import React, { useState, createContext } from 'react';

export const NavContext = createContext();

function NavProvider({ children }) {
  const [title, setTitle] = useState('');

  return (
    <NavContext.Provider value={{ title, setTitle }}>
      {children}
    </NavContext.Provider>
  )
}

export default NavProvider;