import xor from "../../../../../utils/xor";
const VALIDATION_MESSAGES = {
  'owner_renter': 'Owner / renter should be selected.', 
  'homeType_single': 'Please select only one option for Home type.', 
  'notInstalledOther_check': 'If the “other” field is checked then something needs to be typed in the field next to it.', 
  'noFurnace': "Please don't select any field in the Primary or Secondary Furnace section if 'No Furnace' is checked", 
  'primaryFurnace_location': 'Please select primary furnace location.', 
  'secondaryFurnace_location': 'Please select secondary furnace location.', 
  'waterHeater_location': 'Please select Water Heater location.', 
  'roomAmbientCOTest_pass': 'Only (and AT LEAST) one option can be selected for Room Ambient CO Test', 
  'roomAmbientCOTest_testNotRequired': 'Please leave the comments when Room Ambient CO Test "Testing not required."',
  'primaryFurnace_pass': 'Only (and AT LEAST) one option can be selected for Primary Furnace', 
  'secondaryFurnace_pass': 'Only (and AT LEAST) one option can be selected for Secondary Furnace', 
  'waterHeater_pass': 'Only (and AT LEAST) one option can be selected for Water Heater', 
  'waterHeater_testNotRequired': 'Please leave the comments when Water Heater "Testing not required."',
  'oven_pass': 'Only (and AT LEAST) one option can be selected for Oven', 
  'cooktop_pass': 'Only (and AT LEAST) one option can be selected for Cooktop', 
  'broiler_pass': 'Only (and AT LEAST) one option can be selected for Broiler', 
  'gasLogs_pass': 'Only (and AT LEAST) one option can be selected for Gas Logs',
  'primaryFurnace_noAppliance_noLocation': 'When Primary Furnace location “does not exist”, only “No Appliance.” is allowed to select.',
  'primaryFurnace_noAppliance_hasLocation': 'When Primary Furnace location exists, “No Appliance” cannot be selected.',
  // TODO: fix
  'primaryFurnace_type': 'When Primary Furnace location exists, Primary Furnace type cannot be None.',
  'primaryFurnace_operationStatus': 'When Primary Furnace location exists, Primary Furnace Operation Status cannot be Does Not Exist.',

  'secondaryFurnace_noAppliance_noLocation': 'When Secondary Furnace location “does not exist”, only “No Appliance.” is allowed to select.',
  'secondaryFurnace_noAppliance_hasLocation': 'When Secondary Furnace location exists, “No Appliance” cannot be selected.',

  // TODO: fix validation, not currently working
  'secondaryFurnace_type': 'When Secondary Furnace location exists, Secondary Furnace type cannot be None.',

  'secondaryFurnace_operationStatus': 'When Secondary Furnace location exists, Secondary Furnace Operation Status cannot be Does Not Exist.',
  'waterHeater_noAppliance_noLocation': 'When Water Heater location “does not exist”, only “No Appliance.” is allowed to select.',
  'waterHeater_noAppliance_hasLocation': 'When Water Heater location exists, “No Appliance” cannot be selected.',

  // TODO: fix validation 
  'waterHeater_type': 'When Water Heater location exists, Water Heater type cannot be None.',
  'waterHeater_operationStatus': 'When Water Heater location exists, Water Heater Operation Status cannot be Does Not Exist.',
}
const useLogic = (control) => {
  const { getValues } = control;

  return {
    "owner": () => ({
      validate: () => {
        const conditions = ["owner", "renter"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return (count === 1) || VALIDATION_MESSAGES['owner_renter'];
      },
    }),
    "homeType_single": () => ({
      validate: () => {
        const conditions = ["homeType_single", "homeType_multiFamily", "homeType_mobile"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        console.log(conditions, count)
        return (count === 1) || VALIDATION_MESSAGES['homeType_single'];
      },
    }),
    "notInstalledOther_check": () => ({
      validate: (value) => {
        const text = getValues("notInstalledOther_text");
        return ((!!value && !!text) || !value) || VALIDATION_MESSAGES['notInstalledOther_check'];
      },
    }),
    "noFurnace": () => ({
      validate: (isChecked) => {
        const anyPrimaryFurnaceValue = getValues("primaryFurnace_location") || getValues("primaryFurnace_type") || getValues("primaryFurnace_operationStatus");
        const anySecondaryFurnaceValue = getValues("secondaryFurnace_location") || getValues("secondaryFurnace_type") || getValues("secondaryFurnace_operationStatus")
        return (!(isChecked && ((anyPrimaryFurnaceValue && anyPrimaryFurnaceValue !== 'none' && anyPrimaryFurnaceValue !== 'doesNotExist') || anySecondaryFurnaceValue))) || VALIDATION_MESSAGES['noFurnace'];
      },
    }),
    "primaryFurnace_location": () => ({
      validate: (value) => {
        const noFurnace = getValues("noFurnace");
        return (!!value || !!noFurnace) || VALIDATION_MESSAGES['primaryFurnace_location'];
      },
    }),
    "waterHeater_location": () => ({
      validate: {
        required: (value) => {
          return (!!value) || VALIDATION_MESSAGES['waterHeater_location'];
        },
      },
    }),
    "roomAmbientCOTest_pass": () => ({
      validate: () => {
        const conditions = ["roomAmbientCOTest_pass", "roomAmbientCOTest_fail", "roomAmbientCOTest_testNotRequired"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return ((count < 2) && (count === 1)) || VALIDATION_MESSAGES['roomAmbientCOTest_pass'];
      },
    }),
    "roomAmbientCOTest_testNotRequired": () => ({
      validate: (checked) => {
        return !(checked && !getValues("additionalInfo")) || VALIDATION_MESSAGES['roomAmbientCOTest_testNotRequired'];
      },
    }),
    "primaryFurnace_pass": () => ({
      validate: () => {
        const conditions = ["primaryFurnace_pass", "primaryFurnace_fail", "primaryFurnace_inop", "primaryFurnace_noAppliance", "primaryFurnace_hydronic"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return ((count < 2) && (count === 1)) || VALIDATION_MESSAGES['primaryFurnace_pass'];
      },
    }),
    "secondaryFurnace_pass": () => ({
      noLocation: () => {
        const location = getValues("secondaryFurnace_location");
        const conditions = ["secondaryFurnace_noAppliance", "secondaryFurnace_fail", "secondaryFurnace_inop"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return (location !== 'doesNotExist' || count === 0) || VALIDATION_MESSAGES['secondaryFurnace_noAppliance_noLocation'];
      },
      validate: () => {
        const conditions = ["secondaryFurnace_pass", "secondaryFurnace_fail", "secondaryFurnace_inop", "secondaryFurnace_noAppliance"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return ((count < 2) && (count === 1)) || VALIDATION_MESSAGES['secondaryFurnace_pass'];
      },
    }),
    "waterHeater_pass": () => ({
      validate: () => {
        const conditions = ["waterHeater_pass", "waterHeater_fail", "waterHeater_inop", "waterHeater_inaccessible", "waterHeater_noAppliance", "waterHeater_electric", "waterHeater_testNotRequired"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return ((count < 2) && (count === 1)) || VALIDATION_MESSAGES['waterHeater_pass'];
      },
    }),
    "waterHeater_testNotRequired": () => ({
      validate: (checked) => {
        return !(checked && !getValues("additionalInfo")) || VALIDATION_MESSAGES['waterHeater_testNotRequired'];
      },
    }),
    "oven_pass": () => ({
      validate: () => {
        const conditions = ["oven_pass", "oven_fail", "oven_inop", "oven_inaccessible", "oven_noAppliance", "oven_electric"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return ((count < 2) && (count === 1)) || VALIDATION_MESSAGES['oven_pass'];
      },
    }),
    "cooktop_pass": () => ({
      validate: () => {
        const conditions = ["cooktop_pass", "cooktop_fail", "cooktop_inop", "cooktop_inaccessible", "cooktop_noAppliance", "cooktop_electric"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return ((count < 2) && (count === 1)) || VALIDATION_MESSAGES['cooktop_pass'];
      },
    }),
    "broiler_pass": () => ({
      validate: () => {
        const conditions = ["broiler_pass", "broiler_fail", "broiler_inop", "broiler_inaccessible", "broiler_noAppliance", "broiler_electric"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return ((count < 2) && (count === 1)) || VALIDATION_MESSAGES['broiler_pass'];
      },
    }),
    "gasLogs_pass": () => ({
      validate: () => {
        const conditions = ["gasLogs_pass", "gasLogs_fail", "gasLogs_inop", "gasLogs_inaccessible", "gasLogs_noAppliance", "gasLogs_electric"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return ((count < 2) && (count === 1)) || VALIDATION_MESSAGES['gasLogs_pass'];
      },
    }),
    "primaryFurnace_noAppliance": () => ({
      validate: {
        noLocation: () => {
          const location = getValues("primaryFurnace_location");
          const conditions = ["primaryFurnace_pass", "primaryFurnace_fail", "primaryFurnace_inop", "primaryFurnace_hydronic"];
          const count = conditions.filter(condition => !!getValues(condition)).length;
          return (location !== 'doesNotExist' || count === 0) || VALIDATION_MESSAGES['primaryFurnace_noAppliance_noLocation'];
        },
        hasLocation: (value) => {
          const location = getValues("primaryFurnace_location");
          console.log(location, value)
          return (location === "doesNotExist" || location === '' || !value) || VALIDATION_MESSAGES['primaryFurnace_noAppliance_hasLocation'];
        },
      }
    }),
    "primaryFurnace_type": () => ({
      validate: (value) => {
        const location = getValues("primaryFurnace_location");
        return ((!location && location !== "doesNotExist") || value !== "none" ) || VALIDATION_MESSAGES['primaryFurnace_type'];
      },
    }),
    "primaryFurnace_operationStatus": () => ({
      validate: (value) => {
        const location = getValues("primaryFurnace_location");
        return ((!location && location !== "doesNotExist") || value !== "doesNotExist") || VALIDATION_MESSAGES['primaryFurnace_operationStatus'];
      },
    }),
    "secondaryFurnace_noAppliance": () => ({
      validate: {
        noLocation: () => {
          const location = getValues("secondaryFurnace_location");
          const conditions = ["secondaryFurnace_pass", "secondaryFurnace_fail", "secondaryFurnace_inop"];
          const count = conditions.filter(condition => !!getValues(condition)).length;
          return (location !== 'doesNotExist' || count === 0) || VALIDATION_MESSAGES['secondaryFurnace_noAppliance_noLocation'];
        },
        hasLocation: (value) => {
          const location = getValues("secondaryFurnace_location");
          return (location === "doesNotExist" || location === '' || !value) || VALIDATION_MESSAGES['secondaryFurnace_noAppliance_hasLocation'];
        },
      }
    }),
    "secondaryFurnace_type": () => ({
      validate: (value) => {
        const location = getValues("secondaryFurnace_location");
        if (!location && location !== "doesNotExist") 
          return (value !== "none") || VALIDATION_MESSAGES['secondaryFurnace_type'];
      },
    }),
    "secondaryFurnace_operationStatus": () => ({
      validate: (value) => {
        const location = getValues("secondaryFurnace_location");
        if ((!location || location !== "doesNotExist"))
          return (value !== "doesNotExist") || VALIDATION_MESSAGES['secondaryFurnace_operationStatus'];
      },
    }),
    "waterHeater_noAppliance": () => ({
      validate: {
        noLocation: () => {
          const location = getValues("waterHeater_location");
          const conditions = ["waterHeater_pass", "waterHeater_fail", "waterHeater_inop", "waterHeater_hydronic"];
          const count = conditions.filter(condition => !!getValues(condition)).length;
          return (location !== 'doesNotExist' || count === 0) || VALIDATION_MESSAGES['waterHeater_noAppliance_noLocation'];
        },
        hasLocation: (value) => {
          const location = getValues("waterHeater_location");
          return (location === "doesNotExist" || !value) || VALIDATION_MESSAGES['waterHeater_noAppliance_hasLocation'];
        },
      }
    }),
    "waterHeater_type": () => ({
      validate: (value) => {
        const location = getValues("waterHeater_location");
        return ((!location && location !== "doesNotExist") || value !== "none") || VALIDATION_MESSAGES['waterHeater_type'];
      },
    }),
    "waterHeater_operationStatus": () => ({
      validate: (value) => {
        const location = getValues("waterHeater_location");
        return ((!location && location !== "doesNotExist") || value !== "doesNotExist" || value === '') || VALIDATION_MESSAGES['waterHeater_operationStatus'];
      },
    }),
  };
};

export default useLogic;