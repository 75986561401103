import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import MaterialTable from 'material-table';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import PageLayout from 'components/PageLayout/PageLayout';
import BackButton from 'components/BackButton';
import useAPI from 'hooks/useAPI';
import { getPersonName, getCustomerAddress, getCustomerCity } from 'utils/helper'
import qs from 'qs'; 
const DEFAULT_PAGE_SIZE = 10;


function AuditingList() {
  const { register, handleSubmit } = useForm()
  // eslint-disable-next-line no-unused-vars
  const [userLookup, setUserLookup] = useState({})
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState();
  const api = useAPI();
  const history = useHistory();

  // Build Technician list
  useEffect(() => {
    api
      .get('/users')
      .then(res => {
        const users = res.data.users.filter(user => user.role.includes('tech.'));
        if (users && users.length > 0) {
          setUserLookup(users.reduce((acc, user) => ({...acc, [user._id]: `${user.firstName} ${user.lastName}`}), {}))
        }
      })
      .catch(err => console.log(err))
    // eslint-disable-next-line
  }, [])

  const columns = [
    { 
      title: 'Enrollment#', editable: 'never',
      render: (rowData) => rowData.customer?.enrollment?.enrollmentNumber
    },
    { 
      title: 'Days before deadline', editable: 'never',
      render: (rowData) => rowData.customer?.enrollment?.enrollmentNumber
    },
    { 
      title: 'Customer Information', editable: 'never',
      render: (rowData) => (
        <>
          <Typography>{ getPersonName(rowData.customer) }</Typography>
          <Typography>{ getCustomerAddress(rowData.customer) }</Typography>
          <Typography>{ getCustomerCity(rowData.customer) }</Typography>
        </>
      )
    },
    { 
      title: 'Form and Pictures', editable: 'never',
      render: (rowData) => (<Button variant="contained" color="primary" onClick={() => openAppointmentDetail(rowData)}>View Attachments and Process</Button>)
    }
  ];

  const fetchAuditingList = () => {
    const url = `/customers/audit?${qs.stringify({ page, limit: pageSize, ...query }) }`;
    return api.get(url).then(res => res.data)
  }

  const openAppointmentDetail = (appointment) => {
    history.push(`/audit/${appointment._id}`)
  }

  const searchAppointment = (data) => {
    setQuery(data);
  }

  return (
    <PageLayout title={'Auditing'}>
      <Box mb={2} mt={1} p={2}>
        <form onSubmit={handleSubmit(searchAppointment)}>
          <Box display="flex" justifyContent="space-between" my={2} width="100%">
            <Box display="flex" flexWrap="wrap">
              <Box mx={1}>
                <TextField name="enrollmentNumber" placeholder="Enrollment Number" variant="outlined" size="small" inputRef={register} />
              </Box>
              <Box mx={1}>
                <TextField placeholder='Street #' name='streetNumber' variant="outlined" size="small" inputRef={register} />
              </Box>
              <Box mx={1}>
                <TextField placeholder='Street Name' name='streetName' variant="outlined" size="small" inputRef={register} />
              </Box>
              <Box mx={1}>
                <TextField placeholder='City' name='city' variant="outlined" size="small" inputRef={register} />
              </Box>
              <Box mx={1}>
                <TextField placeholder='Zip' name='zip' variant="outlined" size="small" inputRef={register} />
              </Box>
              <Box mx={1}>

              </Box>
            </Box>
            <Box mx={1} display="flex" alignItems="center">
              <Button type='submit' variant="contained" color="primary" onClick={handleSubmit}>Search</Button>
              <BackButton url='/' ml={1} />
            </Box>
          </Box>
        </form>
      </Box>
      <Box p={2}>
        <MaterialTable
          key={query}
          columns={columns}
          data={(query) => fetchAuditingList(query)}
          onChangeRowsPerPage={(pageSize) => {
            setPageSize(pageSize);
          }}
          onChangePage={(page) => setPage(page)}
          options={{
            pageSize,
            emptyRowsWhenPaging: false,
            toolbar: false
          }}
          />
      </Box>
    </PageLayout>
  )
}

export default AuditingList;
