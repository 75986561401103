import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: 'calc(100% - 30px)',
    margin: '0px 15px'
  },
})

export default useStyles;