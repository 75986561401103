import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PrivateRoute from 'components/routing/PrivateRoute';
import PublicRoute from 'components/routing/PublicRoute';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Accounts from 'pages/Accounts';
import UpdateAccount from 'pages/UpdateAccount';
import CustomerBin from 'pages/CustomerBin';
import CustomerInfo from 'pages/CustomerInfo';
import CustomerRegister from 'pages/CustomerRegister';
import { Regions, RegionInvoicing } from 'pages/Invoicing';
import AuditingList from 'pages/AuditingList';
import AuditingAppointment from 'pages/AuditingAppointment';
import Users from 'pages/Users';
import ManageUser from 'pages/ManageUser';
import AccountAccess from 'pages/ManageUser/AccountAccess';
import UserRegistration from 'pages/UserRegistration';
import PasswordReset from 'pages/PasswordReset';
import SubmitAppointment from 'pages/SubmitAppointment';
import ViewForms from 'pages/ViewForms';
import TwoStepVerification from 'pages/TwoStepVerification';

import WXWO from 'pages/FormAudit/Forms/WXWO';
import AIWO from 'pages/FormAudit/Forms/AIWO';
import DUCT from 'pages/FormAudit/Forms/DUCT';
import NGAT from 'pages/FormAudit/Forms/NGAT';
import NOC from 'pages/FormAudit/Forms/NOC';
import WASHER from 'pages/FormAudit/Forms/WASHER';
import ThermCalculator from 'pages/ThermCalculator';
import PRThermCalculator from 'pages/PRThermCalculator';
import LeadFetcher from 'pages/LeadFetcher';
import ThermReport from 'pages/ThermReport/ThermReport';

function Routes() {
  return (
    <Switch>

      {/* accessible by users that are logged in
      and users that are not logged in */}
      <PublicRoute path='/login'>
        <Login />
      </PublicRoute>

      <Route path='/prtherms'>
        <PRThermCalculator/>
      </Route>
      <Route path='/therms'>
        <ThermCalculator/>
      </Route>

      <PrivateRoute path='/home'>
        <Home/>
      </PrivateRoute>

      <PrivateRoute path='/fetch-leads'>
        <LeadFetcher/>
      </PrivateRoute>

      <PrivateRoute path='/therm-report' roles={['admin']}>
        <ThermReport/>
      </PrivateRoute>

      <PrivateRoute path='/two-step-verification'>
        <TwoStepVerification/>
      </PrivateRoute>
      <PrivateRoute path='/users/:userId/accounts/:accountId'>
        <AccountAccess/>
      </PrivateRoute>
      <PrivateRoute exact path='/users/registration'>
        <UserRegistration/>
      </PrivateRoute>
      <PrivateRoute path='/users/:userId'>
        <ManageUser/>
      </PrivateRoute>
      <PrivateRoute path='/users'>
        <Users/>
      </PrivateRoute>

      <PrivateRoute path='/accounts/:accountId'>
        <UpdateAccount />
      </PrivateRoute>
      <PrivateRoute path='/accounts'>
        <Accounts/>
      </PrivateRoute>


      <PrivateRoute path='/customers/:enrollmentNumber/appointment/:appointmentId/form/wxwo/:version/:submissionId' roles={['auditor.initial', 'auditor.final', 'auditor', 'scheduler', 'admin', 'tech.admin']}>
        <WXWO />
      </PrivateRoute>
      <PrivateRoute path='/customers/:enrollmentNumber/appointment/:appointmentId/form/washer/:version/:submissionId' roles={['auditor.initial', 'auditor.final', 'auditor', 'scheduler', 'admin', 'tech.admin']}>
        <WASHER />
      </PrivateRoute>
      <PrivateRoute path='/customers/:enrollmentNumber/appointment/:appointmentId/form/aiwo/:version/:submissionId' roles={['auditor.initial', 'auditor.final', 'auditor', 'scheduler', 'admin', 'tech.admin']}>
        <AIWO />
      </PrivateRoute>
      <PrivateRoute path='/customers/:enrollmentNumber/appointment/:appointmentId/form/duct/:version/:submissionId' roles={['auditor.initial', 'auditor.final', 'auditor', 'scheduler', 'admin', 'tech.admin']}>
        <DUCT />
      </PrivateRoute>
      <PrivateRoute path='/customers/:enrollmentNumber/appointment/:appointmentId/form/ngat/:version/:submissionId' roles={['auditor.initial', 'auditor.final', 'auditor', 'scheduler', 'admin', 'tech.admin']}>
        <NGAT />
      </PrivateRoute>
      <PrivateRoute path='/customers/:enrollmentNumber/appointment/:appointmentId/form/noc/:version/:submissionId' roles={['auditor.initial', 'auditor.final', 'auditor', 'scheduler', 'admin', 'tech.admin']}>
        <NOC />
      </PrivateRoute>
      <PrivateRoute exact path='/customers/:enrollmentNumber/appointment/:appointmentId' roles={['auditor.initial', 'auditor.final', 'auditor', 'scheduler', 'admin', 'tech.admin']}>
        <ViewForms />
      </PrivateRoute>
      <PrivateRoute exact path='/customers/:enrollmentNumber/appointment/:appointmentId/reassign' roles={['scheduler', 'auditor', 'admin', 'tech.admin']}>
        <SubmitAppointment />
      </PrivateRoute>
      <PrivateRoute exact path='/customers/:enrollmentNumber/submit-appointment' roles={['scheduler', 'auditor', 'admin', 'tech.admin']}>
        <SubmitAppointment />
      </PrivateRoute>
      <PrivateRoute path='/customers/register'>
        <CustomerRegister />
      </PrivateRoute>
      <PrivateRoute path='/customers/:enrollmentNumber'>
        <CustomerInfo />
      </PrivateRoute>
      <PrivateRoute exact path='/customers'>
        <CustomerBin />
      </PrivateRoute>
      <PrivateRoute exact path='/audit' roles={['auditor.initial', 'auditor.final', 'auditor', 'admin']}>
        <AuditingList />
      </PrivateRoute>
      <PrivateRoute exact path='/audit/:appointmentId' roles={['auditor.initial', 'auditor.final', 'auditor', 'admin']}>
        <AuditingAppointment />
      </PrivateRoute>
      <PrivateRoute exact path='/invoicing' roles={['admin']}>
        <Regions />
      </PrivateRoute>
      <PrivateRoute exact path='/invoicing/:region' roles={['admin']}>
        <RegionInvoicing />
      </PrivateRoute>

      <PrivateRoute path='/reset-password'>
        <PasswordReset/>
      </PrivateRoute>

      <Route exact path='/'>
        <Redirect to='/login'/>
      </Route>
    </Switch>
  )
}

export default Routes;