import styled from "styled-components";

const Input = styled.input.attrs(({ field, page }) => ({
  style: {
    top: field.yMod * page.height,
    left: field.xMod * page.width,
    height: field.heightMod * page.height,
    width: field.widthMod * page.width,
  },
}))`
  position: absolute;
  outline: none;
  border: none;
  background: none;
  font-size: 1rem;

  &:focus {
    outline: 3px solid #2bd1e3;
  }
`;

export default Input;
