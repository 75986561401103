import { createRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";

/* Main Components */
const SignatureModal = (props) => {
  const { fieldName, setFieldName, setValue, getValues } = props; // from parent
  let modalSignatureRef = createRef();

  useEffect(() => {
    if (fieldName) {
      const signatureFieldValue = getValues(fieldName);
      if (modalSignatureRef) {
        modalSignatureRef.fromDataURL(signatureFieldValue, "image/png");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, modalSignatureRef]);

  const closeModal = () => {
    setFieldName(null);
  }
  const clearSignature = () => {
    modalSignatureRef.clear();
  }
  return (
    <div
      className={`modal-wrapper ${ !!fieldName ? 'active' : '' }`}
      >
      <div className="modal">
        <h2>Draw Signature</h2>
        <div className="signature-wrapper">
          <SignatureCanvas 
            ref={(ref) => {
              modalSignatureRef = ref;
            }}
            onEnd={() => {
              setValue(fieldName, modalSignatureRef.getTrimmedCanvas().toDataURL('image/png'));
            }}
            canvasProps={{
              className: 'signature-canvas'
            }}
            />
        </div>
        <div className="button-row">
          <span className="btn" onClick={clearSignature}>Clear</span>
          <span className="btn" onClick={closeModal}>Done</span>
        </div>
      </div>
    </div>
  );
}

export default SignatureModal;