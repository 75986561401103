import React, { useEffect, useState } from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/BackButton';
import useAPI from 'hooks/useAPI';
import useUser from 'hooks/useUser';
import useAccounts from 'hooks/useAccounts';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import StyledCard from 'components/StyledCard';
import { toast } from 'react-toastify';
import useStyles from './styles';

import useGlobalLoading from 'hooks/useGlobalLoading';

function CustomerRegister() {
  const { setIsLoading } = useGlobalLoading();
  const { user } = useUser();
  const { ownedAccounts, sharedAccounts } = useAccounts(user._id)
  const api = useAPI();
  const history = useHistory();
  const styles = useStyles();
  const [enrollmentNumber, setEnrollmentNumber] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(sessionStorage.getItem('id'))

  useEffect(() => {
    sessionStorage.setItem('id', selectedAccount)
  }, [selectedAccount])

  const onSelectAccount = (accountId) => {
    localStorage.setItem('accountId', accountId)
    if (selectedAccount === accountId) {
      setSelectedAccount(null);
      localStorage.setItem('accountId', null)
    } 
    else setSelectedAccount(accountId);
  }

  const handleSubmit = () => {
    setIsLoading(true)
    if (!selectedAccount || selectedAccount === 'null')  {
      alert('Please select a utility account from the above list before trying to add a new customer from the Heat system.');
      setIsLoading(false)
      return;
    }
    if (!enrollmentNumber) {
      alert('Please input a valid enrollment number.');
      setIsLoading(false)
      return;
    }
    api
      .get(`/heat/${selectedAccount}/enrollment/${enrollmentNumber}`)
      .then((res) => {
        toast.success(res?.data?.message || `A customer registered successfully`);
        history.push(`/customers/${enrollmentNumber}`)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false));

  }

  return (
    <PageLayout title="Add a new customer from heat system">
      <Container maxWidth="md">
        <Box display="flex" my={1} justifyContent="flex-end">
          <BackButton url="/customers" ml={1} />
        </Box>
        <Box display='flex' alignItems="center" >
          <Box mr={2}>
            <Typography className={styles.selectText}>Select a utility account</Typography>
          </Box>
          <Box display='flex' flexGrow={1} mr={2} className={styles.wrapper}>
            {ownedAccounts.map(account => (
              <Box 
                key={account._id} flexDirection='column' width='20%' mr={2} flexShrink={0}
                onClick={() => onSelectAccount(account._id)}>
                <StyledCard className={ selectedAccount === account._id ? styles.selectedBox : styles.box }>
                  <Typography>{ account.type }</Typography>
                  <Box my={1}>
                    <TextField value={ account.username } variant="outlined" size="small" disabled />
                  </Box>
                  <Typography variant="caption" className={ styles.selectedText }>{selectedAccount === account._id ? <>* Currently Selected</> : <>&nbsp;</> }</Typography>
                </StyledCard>
              </Box>
            ))}
            {sharedAccounts.map(account => (
              <Box 
                key={account._id} flexDirection='column' width='20%' mr={2} flexShrink={0}
                onClick={() => sessionStorage.setItem('id', account._id)}>
                <StyledCard className={ selectedAccount === account._id ? styles.selectedBox : '' }>
                  <Typography>{ account.type }</Typography>
                  <Box my={1}>
                    <TextField value={ account.username } variant="outlined" size="small" disabled />
                  </Box>
                  <Typography variant="caption" className={ styles.selectedText }>{sessionStorage.getItem('id') === account._id ? <>* Currently Selected</> : <>&nbsp;</> }</Typography>
                </StyledCard>
              </Box>
            ))}
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' padding='5px'>
          <Box mr={5}>
            <TextField name="enrollmentNumber" placeholder="Enrollment Number" variant="outlined" size="small" value={enrollmentNumber} onChange={(e) => setEnrollmentNumber(e.target.value)} />
          </Box>
          <Box>
            <Button type='submit' variant="contained" color="primary" onClick={handleSubmit}>Search</Button>
          </Box>
        </Box>
      </Container>
    </PageLayout>
  )
}

export default CustomerRegister;