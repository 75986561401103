import { useEffect, useContext, useState } from 'react';
import useAPI from 'hooks/useAPI';
import useAuth from 'hooks/useAuth';
import { UserContext } from 'providers/UserProvider';
import { useHistory } from 'react-router-dom';

function useUser() {
  const { setAccessToken, decoded } = useAuth();
  const { user, setUser } = useContext(UserContext);
  const api = useAPI();
  const history = useHistory();

  useEffect(() => {
    if (decoded) {
    api
      .get(`/users/${decoded.id}`)
      .then(res => setUser(res.data.user))
      .catch(err => {
        console.log(err)
        setUser(null)
        setAccessToken(null)
      })
    }
    else {
      setUser(null)
      history.push("/login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decoded])

  return { user, setUser, isAuthed: !!user };
}

export default useUser;
