import { useEffect, useState } from 'react';
import useAPI from 'hooks/useAPI';

function useAccounts(id, open) {
  const [ownedAccounts, setOwnedAccounts] = useState([]);
  const [sharedAccounts, setSharedAccounts] = useState([]);
  const api = useAPI();

  useEffect(() => {
    api
      .get(`/users/${id}/utility-accounts`)
      .then(res => setOwnedAccounts(res.data.accounts))
      .catch(err => console.log(err))

    api
      .get(`/users/${id}/utility-accounts/shared`)
      .then(res => setSharedAccounts(res.data.accounts))
      .catch(err => console.log(err))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return { ownedAccounts, setOwnedAccounts, sharedAccounts, setSharedAccounts }
}

export default useAccounts