import * as React from "react";
import {
  Dialog,
  Box,
  Button,
  Typography
} from "@material-ui/core";

const AlertDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose
}) => {
  return (
    <Dialog open={open} size="sm">
      <Box width={400} p={3}>
        <Typography>{description}</Typography>
        <Box mt={3} textAlign="center">
          {variant === "danger" ? (
            <>
              <Button color="primary" onClick={onSubmit}>
                Yes, I agree
              </Button>
              <Button color="primary" onClick={onClose} autoFocus>
                CANCEL
              </Button>
            </>
          )
          :
          (
            <Button color="primary" variant="contained" onClick={onSubmit}>
              OK
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AlertDialog;