import { useState, useEffect } from 'react';
import useAPI from 'hooks/useAPI';
import useGlobalLoading from 'hooks/useGlobalLoading';

function useCustomer(enrollmentNumber) {
  const api = useAPI();
  const { setIsLoading } = useGlobalLoading();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    setIsLoading(true)
    api
      .get(`/heat/${sessionStorage.getItem('id')}/enrollment/${enrollmentNumber}`)
      .then(res => {
        setCustomer(res.data.customer)
      })
      .catch(err => console.log(err))
      .finally(setIsLoading(false))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { customer }
}

export default useCustomer;