import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

function PublicRoute(props) {
  const { accessToken } = useAuth();

  if (accessToken) {
    return <Redirect to='/home' />
  }
  else {
    return <Route {...props} />
  }
}

export default PublicRoute;