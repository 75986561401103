import React from 'react';
import { useHistory } from 'react-router-dom';
import useUser from 'hooks/useUser';
import useAuth from 'hooks/useAuth';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import BugReportIcon from '@mui/icons-material/BugReport';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CalculateIcon from '@mui/icons-material/Calculate';


const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    paddingRight: 100
  }
}))

const routes = [
  {
    path: '/home',
    text: 'Home',
    icon: HomeIcon
  },
  {
    path: '/customers',
    text: 'Customer Bin',
    icon: EmojiPeopleIcon
  },
  {
    path: '/invoicing',
    text: 'Invoicing',
    icon: ReceiptIcon,
    roles: ['admin']
  },
  {
    path: '/accounts',
    text: 'Utility Accounts',
    icon: AssignmentIndIcon
  },
  {
    path: '/users',
    text: 'Manage Users',
    icon: FaceIcon,
    roles: ['admin']
  },
  {
    path: '/users/registration',
    text: 'Register Users',
    icon: AddIcon,
    roles: ['admin']
  },
  {
    path: '/customers/register',
    text: 'Add new customer',
    icon: AddIcon,
    roles: ['admin', 'scheduler']
  },
  {
    path: '/reset-password',
    text: 'Reset Password',
    icon: VpnKeyIcon
  },
  {
    path: '/therms',
    text: 'Therms Calculator',
    icon: EnergySavingsLeafIcon,
  },
  {
    path: 'fetch-leads',
    text: 'Fetch Lead Information',
    icon: ContactMailIcon
  },
  {
    path: 'therm-report',
    text: 'Calculate Total Therms',
    icon: CalculateIcon,
    rolees: ['admin']
  }
]

function SideBar({ open, toggleDrawer }) {
  const { user, setUser } = useUser();
  const { setAccessToken } = useAuth()
  const history = useHistory();
  const styles = useStyles();

  const drawerAction = action => () => {
    action();
    toggleDrawer();
  }

  const logout = drawerAction(() => {
    localStorage.removeItem('accessToken');
    setAccessToken(null)
    setUser(null);
    history.push('/login');
  })

  return (
    <Drawer 
      anchor='left' 
      open={open} 
      onClose={toggleDrawer}
    >
      <List className={styles.list}>
        {!!user && routes.filter(route => route.roles ? route.roles.includes(user?.role) : true).map((route) => (
          <React.Fragment key={route.path}>
          <ListItem button onClick={drawerAction(() => history.push(route.path))} className={styles.listItem}>
            <ListItemIcon>
              <route.icon />
            </ListItemIcon>
            <ListItemText primary={route.text} />
          </ListItem>

          <Divider />
          </React.Fragment>
        ))}

        <ListItem button onClick={() => window.open('https://forms.gle/2gWwEN6HFb4CnTsNA')} className={styles.listItem}>
          <ListItemIcon>
            <BugReportIcon />
          </ListItemIcon>
          <ListItemText primary='Report A Bug' />
        </ListItem>
        <Divider />

        <ListItem button onClick={logout} className={styles.listItem}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText primary='Log Out' />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default SideBar;