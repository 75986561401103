const VALIDATION_MESSAGES = {
  "existingAtticInsulationRValue_numbers": "Existing attic insulation R value cannot be blank",
  "existingAtticInsulationType_textOnlyNoNumbers": "Existing attic insulation type must not be blank",
  "atticSquareFootage_numbers": "Attic square footage cannot be left blank",
  "existingAtticVentilation.upper_numbers": "Existing attic ventilation upper cannot be left blank",
  "existingAtticVentilation.lower_numbers": "Existing attic ventilation lower cannot be left blank",
  "requiredAtticVentilation.upper_numbers": "Required attic ventilation upper cannot be left blank",
  "requiredAtticVentilation.lower_numbers": "Required attic ventilation lower cannot be left blank",
  "addedAtticVentilation.upper_numbers": "Added attic ventilation upper cannot be left blank",
  "addedAtticVentilation.lower_numbers": "Added attic ventilation lower cannot be left blank",
  "installedRValue.attic_numbers": "Installed r value attic cannot be blank",
  "atticQty_numbers": "Attic installed qty cannot be blank",
  "details_brand": "Details brand cannot be left blank",
  "details_make": "Details make cannot be left blank",
  "installedRValue.kneeWall_numbers": "Knee Wall R-Value Numbers only",
  "kneeWallQty_numbers": "Knee Wall Qty Numbers only",
  "atticAccessCoverQty_numbers": "Attic Access Cover Qty Numbers only",
  "atticAccessNewQty_numbers": "Attic Access New Qty Numbers only",
  "ductRepairQty_numbers": "Duct Repair Qty Numbers only",
  "exhaustVentOnlyQty_numbers": "Exhaust Vent Only Qty Numbers only",
  "exhaustVentCutOpeningQty_numbers": "Exhaust Vent- Cut Opening Qty Numbers only",
  "ventDormerQty_numbers": "Vent- Dormer Qty Numbers only",
  "ventEaveQty_numbers": "Vent- Eave Qty Numbers only",
  "ventGableMushroomQty_numbers": "Vent- Gable/Mushroom Qty Numbers only",
  "ventScreenQty_numbers": "Vent- Screen Qty Numbers only",
  "additionalServiceQty_numbers": "Additional Service Qty Numbers only",
  "coSmokeAlarmComboQty_numbers": "CO/Smoke Alarm Combo Qty Numbers only",
  "permitFeeAmount_numbers": "Permit Free Amount Numbers only",
  "smokeAlarmQty_numbers": "Smoke Alarm Qty Numbers only",
  "programIdBadgeNumber_numbers": "Program ID Badge Number Numbers only",
  "programIdBadgeNumber_required": "Program ID Badge Number Numbers required",
  "installerSignature_required": "Installer Signature is required.",
  "customerSignature_required": "Customer Signature is required.",
} 

const useValidation = (control) => {
  const { getValues } = control;

  return {
    "existingAtticInsulationRValue": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["existingAtticInsulationRValue_numbers"]
    }),
    "existingAtticInsulationType": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["existingAtticInsulationType_textOnlyNoNumbers"]
    }),
    "atticSquareFootage": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["atticSquareFootage_numbers"]
    }),
    "existingAtticVentilation.upper": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["existingAtticVentilation.upper_numbers"]
    }),
    "existingAtticVentilation.lower": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["existingAtticVentilation.lower_numbers"]
    }),
    "requiredAtticVentilation.upper": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["requiredAtticVentilation.upper_numbers"]
    }),
    "requiredAtticVentilation.lower": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["requiredAtticVentilation.lower_numbers"]
    }),
    "addedAtticVentilation.upper": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["addedAtticVentilation.upper_numbers"]
    }),
    "addedAtticVentilation.lower": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["addedAtticVentilation.lower_numbers"]
    }),
    "installedRValue.attic": () => ({
      validate: (value) => (!!value) || VALIDATION_MESSAGES["installedRValue.attic_numbers"]
    }),
    "atticQty": () => ({
      validate: (value) => {
        console.log({value})
        return (!!value) || VALIDATION_MESSAGES["atticQty_numbers"]
      }
    }),
    // "details.brand": () => ({
    //   validate: {
    //     required: (value) => {
    //       const detailsBrand = getValues('details.brand')
    //       return (!!detailsBrand || !!value) || VALIDATION_MESSAGES['details_brand']
    //     }
    //   }
    // }),
    // "details.make": () => ({
    //   validate: {
    //     required: (value) => {
    //       const detailsMake = getValues('details.make')
    //       return (!!detailsMake || !!value) || VALIDATION_MESSAGES['details_make']
    //     }
    //   }
    // }),
    "installedRValue.kneeWall": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["installedRValue.kneeWall_numbers"]
    }),
    "details.kneeWall": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["details.kneeWall_numbers"]
    }),
    "kneeWallQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["kneeWallQty_numbers"]
    }),
    "atticAccessCoverQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["atticAccessCoverQty_numbers"]
    }),
    "atticAccessNewQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["atticAccessNewQty_numbers"]
    }),
    "ductRepairQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["ductRepairQty_numbers"]
    }),
    "exhaustVentOnlyQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["exhaustVentOnlyQty_numbers"]
    }),
    "exhaustVentCutOpeningQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["exhaustVentCutOpeningQty_numbers"]
    }),
    "ventDormerQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["ventDormerQty_numbers"]
    }),
    "ventEaveQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["ventEaveQty_numbers"]
    }),
    "ventGableMushroomQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["ventGableMushroomQty_numbers"]
    }),
    "ventScreenQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["ventScreenQty_numbers"]
    }),
    "additionalServiceQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["additionalServiceQty_numbers"]
    }),
    "coSmokeAlarmComboQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["coSmokeAlarmComboQty_numbers"]
    }),
    "permitFeeAmount": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["permitFeeAmount_numbers"]
    }),
    "smokeAlarmQty": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["smokeAlarmQty_numbers"]
    }),
    "permitNumber": () => ({
      validate: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["permitNumber_numbers"]
    }),
    "programIdBadgeNumber": () => ({
      validate: {
        required: (value) => {
          return (!!value) || VALIDATION_MESSAGES["programIdBadgeNumber_required"]
        },
        // number: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["programIdBadgeNumber_numbers"]
      }
    }),
    "customerSignature": () => ({
      validate: (signature) => (!!signature) || VALIDATION_MESSAGES["customerSignature_required"]
    }),
    "installerSignature": () => ({
      validate: (signature) => (!!signature) || VALIDATION_MESSAGES["installerSignature_required"]
    })
  };
};

export default useValidation;
