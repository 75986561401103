import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import useStatuses from './useStatuses';
import usePagination from './usePagination';
import useCustomers from 'hooks/useCustomers';

import PageLayout from 'components/PageLayout';
import FlexContainer from 'components/FlexContainer';
import BackButton from 'components/BackButton';
import useUser from 'hooks/useUser';
import useAccounts from 'hooks/useAccounts';

import useStyles from './styles';
import Select from 'react-select';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { getPersonName, getCustomerAddress, getCustomerCity } from 'utils/helper'
import StyledCard from 'components/StyledCard';

function CustomerBin() {
  const { register, handleSubmit, control } = useForm()
  const styles = useStyles();
  const { user } = useUser();
  const { ownedAccounts, sharedAccounts } = useAccounts(user._id)
  const { 
    handleChangePage, 
    handleChangeRowsPerPage,
    page,
    rows,
    setRows,
    rowsPerPage,
    totalCustomers,
  } = usePagination();
  const { statuses, statusOptions } = useStatuses();
  
  console.log("statuses", statuses);
  const { viewCustomer, search } = useCustomers({ setRows });
  const [selectedAccount, setSelectedAccount] = useState(sessionStorage.getItem('id'))

  useEffect(() => {
    sessionStorage.setItem('id', selectedAccount)
  }, [selectedAccount])

  const onSelectAccount = (accountId) => {
    localStorage.setItem('accountId', accountId)
    if (selectedAccount === accountId) setSelectedAccount(null); else setSelectedAccount(accountId);
  }

  return (
    <PageLayout title='Customers'>
        <Box display='flex' alignItems="center" >
          <Box mr={2}>
            <Typography className={styles.selectText}>Select a utility account</Typography>
          </Box>
          <Box display='flex' flexGrow={1} mr={2} className={styles.wrapper}>
            {ownedAccounts.map(account => (
              <Box 
                key={account._id} flexDirection='column' width='20%' mr={2} flexShrink={0}
                onClick={() => onSelectAccount(account._id)}>
                <StyledCard className={ selectedAccount === account._id ? styles.selectedBox : styles.box }>
                  <Typography>{ account.type }</Typography>
                  <Box my={1}>
                    <TextField value={ account.username } variant="outlined" size="small" disabled />
                  </Box>
                  <Typography variant="caption" className={ styles.selectedText }>{selectedAccount === account._id ? <>* Currently Selected</> : <>&nbsp;</> }</Typography>
                </StyledCard>
              </Box>
            ))}
            {sharedAccounts.map(account => (
              <Box 
                key={account._id} flexDirection='column' width='20%' mr={2} flexShrink={0}
                onClick={() => sessionStorage.setItem('id', account._id)}>
                <StyledCard className={ selectedAccount === account._id ? styles.selectedBox : '' }>
                  <Typography>{ account.type }</Typography>
                  <Box my={1}>
                    <TextField value={ account.username } variant="outlined" size="small" disabled />
                  </Box>
                  <Typography variant="caption" className={ styles.selectedText }>{sessionStorage.getItem('id') === account._id ? <>* Currently Selected</> : <>&nbsp;</> }</Typography>
                </StyledCard>
              </Box>
            ))}
          </Box>
        </Box>
        <FlexContainer 
          flexDirection='column' 
          alignItems='center'
        >
          <form onSubmit={handleSubmit(search)} className="w-full">
            <Box display="flex" justifyContent="space-between" my={2} width="100%">
              <Box display="flex" flexDirection='column' justifyContent='space-between'>
                <Box display='flex' justifyContent='space-between' padding='5px'>
                  <Box mr={5}>
                    <TextField name="enrollmentNumber" placeholder="Enrollment Number" variant="outlined" size="small" inputRef={register} />
                  </Box>
                  <Box mx={1}>
                    <TextField placeholder='Street #' name='streetNumber' variant="outlined" size="small" inputRef={register} />
                  </Box>
                  <Box mx={1}>
                    <TextField placeholder='Street Name' name='streetName' variant="outlined" size="small" inputRef={register} />
                  </Box>
                  <Box mx={1}>
                    <TextField placeholder='City' name='city' variant="outlined" size="small" inputRef={register} />
                  </Box>
                </Box>
                <Box display='flex' justifyContent='flex-start' padding='5px' width='100%'>
                  <Box mx={1}>
                    <TextField placeholder='Zip' name='zip' variant="outlined" size="small" inputRef={register} />
                  </Box>
                  <Box mx={1}>
                    <TextField placeholder='Phone' name='phone' variant="outlined" size="small" inputRef={register} />
                </Box>
                <Box mx={1} width='300px' height='200px'>
                  <Controller
                    name='status'
                    control={control}
                    defaultValue=''
                    render={({ onChange, value, ref }) => {
                      return <Select
                        isClearable
                        inputRef={ref}
                        options={statusOptions}
                        placeholder="Status"
                        value={statusOptions.find(c => c.value === value)}
                        onChange={val => onChange(val.value)}
                        menuPortalTarget={document.body} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box mx={1} display="flex" alignItems="center">
              <Button type='submit' variant="contained" color="primary" onClick={handleSubmit}>Search</Button>
              <BackButton url="/" ml={1} />
            </Box>
          </Box>
        </form>
        {/* {customers && customers.map(customer => <p key={customer.enrollmentNumber}>{customer.firstName}</p>)} */}
        <Table className={styles.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Enrollment #</TableCell>
              <TableCell>Days before deadline</TableCell>
              <TableCell>Customer Information</TableCell>
              <TableCell>Customer Page</TableCell>
            </TableRow>
            {rows
              .map((customer, index) => (
                <TableRow key={customer._id}>
                  <TableCell>{customer.enrollment.enrollmentNumber}</TableCell>
                  <TableCell>{15}</TableCell>
                  <TableCell>
                    <p>{`${getPersonName(customer)}`}</p>
                    <p>{`${getCustomerCity(customer)}`}</p>
                    <p>{`${getCustomerAddress(customer)}`}</p>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      color='primary'
                      className={styles.btn}
                      onClick={() => viewCustomer(customer.enrollment.enrollmentNumber)}
                    >
                      View Customer Info
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableHead>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={totalCustomers}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FlexContainer>
    </PageLayout>
  )
}

export default CustomerBin;