import { createRef } from "react";

export const generateRefs = (fields) =>
  Object.keys(fields).reduce((obj, field) => {
    if (field.needsRef) obj[field.name] = createRef();
    return obj;
  }, {});

export const generateDefaultValues = (fields) => 
  Object.values(fields).reduce((dv, field) => {
    dv[field.name] = field.type === "checkbox" ? false : undefined;
    return dv;
  }, {});


export const generateErrorMessages = (errors) => {
  return Object.values(errors).reduce((acc, field) => [...acc, ...errorFromField(field)], [])
}

const errorFromField = (field) => {
  if (!field) return;
  if (field.type) {
    return [field.message || ''];
  } else {
    return Object.values(field).reduce((acc, item) => [...acc, ...errorFromField(item)], [])
  }

}