import { useState, useEffect, useCallback } from "react";

const useResizablePage = ({ width, height }) => {
  const [page, setPage] = useState({ width, height });

  const handleAspectRatio = useCallback(() => {
    const aspectRatio = height / width;

    setPage({
      width: window.innerWidth < width ? window.innerWidth : width,
      height:
        window.innerWidth < width
          ? window.innerWidth * aspectRatio
          : width * aspectRatio,
    });
  }, [width, height]);

  useEffect(() => {
    window.addEventListener("resize", handleAspectRatio);

    handleAspectRatio();

    return () => window.removeEventListener("resize", handleAspectRatio);
  }, [handleAspectRatio]);

  return { page };
};

export default useResizablePage;
