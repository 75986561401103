import React, { useEffect, useState, createContext } from 'react';
import jwtdecode from 'jwt-decode';

export const AccessTokenContext = createContext();

function AccessTokenProvider({ children }) {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [decoded, setDecoded] = useState(accessToken ? jwtdecode(accessToken) : null)

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      setDecoded(jwtdecode(accessToken));
    }
  }, [accessToken]);

  return (
    <AccessTokenContext.Provider value={{ accessToken, setAccessToken, decoded }}>
      {children}
    </AccessTokenContext.Provider>
  )
}

export default AccessTokenProvider;