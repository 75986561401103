const ROLE_LABEL = {
  'admin': 'Admin',
  'auditor.initial': 'Initial Auditor',
  'auditor.final': 'Final Auditor',
  'auditor': 'Auditor',
  'scheduler': 'Scheduler',
  'tech.wx': 'WX Technician',
  'tech.attic': 'Attic Technician',
  'tech.admin': 'Tech Admin'
}

export const ROLE_OPTIONS = [
  { value: 'admin', label: 'Admin' },
  { value: 'auditor.initial', label: 'Initial Auditor'},
  { value: 'auditor.final', label: 'Final Auditor'},
  { value: 'auditor', label: 'Auditor'},
  { value: 'scheduler', label: 'Scheduler' },
  { value: 'tech.wx', label: 'WX Technician' },
  { value: 'tech.attic', label: 'Attic Technician' },
  { value: 'tech.admin', label: 'Tech Admin' }
]

export const UTILITY_ACCOUNT_OPTIONS = [
  { value: 'HEAT', label: 'HEAT' },
  { value: 'ESA', label: 'ESA' }
]


const formTypeLabel = {
  wxwo: 'Weatherization Work Order',
  ngat: 'Natural Gass Appliance Testing',
  aiwo: 'Attic Insulation Work Order',
  noc: 'Notice of Unsatisfactory Condition',
  duct: 'Perscriptive Duct Sealing Work Order',
  ladwp: 'Los Angeles Department of Water & Power(LADWP) Work Order'
};

const buildArrayOfNonEmptyFields = (originalObject, concerningFields) => {
  const parts = [];
  for (const field of concerningFields) {
    if (originalObject && originalObject[field]) parts.push(originalObject[field]);
  }
  return parts;
}

export const getPersonName = (person) => {
  const fields = ['firstName', 'middleName', 'lastName']
  const parts = buildArrayOfNonEmptyFields(person, fields);
  return parts.join(' ')
}

export const getCustomerAddress = (customer) => {
  const fields = ['streetName', 'streetNumber']
  const parts = buildArrayOfNonEmptyFields(customer, fields);
  return parts.join(' ')
}

export const getCustomerCity = (customer) => {
  const fields = ['city', 'zip']
  const parts = buildArrayOfNonEmptyFields(customer, fields);
  return parts.join(', ')
}

export const getFormLabel = (form) => {
  return `${formTypeLabel[form.type]}`; // - ${dayjs(form?.createdAt).format('MM-DD-YYYY - hh:mm a')}`;
}

export const getRoleLabel = (role) => {
  return ROLE_LABEL[role] 
}

export const isSuccessfulResponse = (res) => {
  return (res && res.status === 200 && res.data);
}