import React from 'react';
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

function BackButton({ url, history, buttonProps, ...props }) {

  const onClick = () => {
    history.push(url);
  };

  return (
    <Box {...props}>
      <Button variant="contained" color="secondary" onClick={onClick} {...buttonProps}>Back</Button>
    </Box>
    );
}

export default withRouter(BackButton);