const formDefaults = {
  "airSealingEnvelope": false,
  "atticInsulation": false,
  "atticInsulationCAM": false,
  "furnaceC&T": false,
  "furnaceRepair": false,
  "prescriptiveDuctSealing": false,
  "washers": false,
  "tankAndPipeInsulation": false,
  "waterHeaterRepairReplace": false,
  "otherHotWater": false, 
  "tubDiverterSpout": 0,
  "thermostaticShowerValve": 0,
  "hefauEarlyReplace": false,
  "hefauOnBurnout": false,
  "wallFurnaceEarlyReplace": false,
  "wallFurnaceOnBurnout": false,
  "solarWaterHeating": false,
  "co&SmokeAlarms": false,
  "smartThermostat": false,
  "storageWaterHeater": false,
  "tanklessWaterHeater81": false,
  "tanklessWaterHeater87": false,
  "tanklessWaterHeater95": false,
  "solarThermalWaterHeatingWStorage": false,
  "solarThermalWaterHeatingWTankless": false,
  "centralFurnace92": false,
  "centralFurnace95": false,
  "centralFurnace97": false,
  "tanklessWaterHeater": false,
  "smartFanController": false
}

const thermValues = {
  "smartFanController": {
    "4": "35.85",
    "5": "40.60",
    "6": "26.32",
    "8": "23.40",
    "9": "23.73",
    "10": "25.46",
    "13": "36.69",
    "14": "33.57",
    "15": "9.58",
    "16": "24.51"
  },
  "airSealingEnvelope": {
    "4": "0",
    "5": "0",
    "6": "0",
    "8": "0",
    "9": "4.12",
    "10": "0",
    "13": "0",
    "14": "0",
    "15": "0",
    "16": "0"
  },
  "atticInsulation": {
    "4": "33.45",
    "5": "31.27",
    "6": "30.04",
    "8": "20.98",
    "9": "29.61",
    "10": "26.72",
    "13": "46.24",
    "14": "36.87",
    "15": "20.07",
    "16": "32.46"
  },
  "atticInsulationCAM": {
    "4": "16.58",
    "5": "16.58",
    "6": "16.58",
    "8": "16.58",
    "9": "16.58",
    "10": "16.58",
    "13": "16.58",
    "14": "16.58",
    "15": "16.58",
    "16": "16.58"
  },
  "furnaceC&T": {
    "4": "-1.23",
    "5": "-1.23",
    "6": "-1.23",
    "8": "-1.23",
    "9": "-1.23",
    "10": "-1.23",
    "13": "-1.23",
    "14": "-1.23",
    "15": "-1.23",
    "16": "-1.23"
  },
  "furnaceRepair": {
    "4": "-40.79",
    "5": "-38.14",
    "6": "-38.33",
    "8": "-42.39",
    "9": "-46.03",
    "10": "-22.30",
    "13": "-27.87",
    "14": "-20.40",
    "15": "-24.47",
    "16": "-39.59"
  },
  "prescriptiveDuctSealing": {
    "4": "11.11",
    "5": "11.11",
    "6": "11.11",
    "8": "11.11",
    "9": "11.11",
    "10": "11.11",
    "13": "11.11",
    "14": "11.11",
    "15": "11.11",
    "16": "11.11"
  },
  "washers": {
    "4": "19.89",
    "5": "19.89",
    "6": "19.89",
    "8": "19.89",
    "9": "19.89",
    "10": "19.89",
    "13": "19.89",
    "14": "19.89",
    "15": "19.89",
    "16": "19.89"
  },
  "tankAndPipeInsulation": {
    "4": "6.35",
    "5": "6.35",
    "6": "6.35",
    "8": "6.35",
    "9": "6.35",
    "10": "6.35",
    "13": "6.35",
    "14": "6.35",
    "15": "6.35",
    "16": "6.35"
  },
  "waterHeaterRepairReplace": {
    "4": "2.39",
    "5": "2.39",
    "6": "2.39",
    "8": "2.39",
    "9": "2.39",
    "10": "2.39",
    "13": "2.39",
    "14": "2.39",
    "15": "2.39",
    "16": "2.39"
  },
  "otherHotWater": {
    "4": "3.16",
    "5": "3.16",
    "6": "3.16",
    "8": "3.16",
    "9": "3.16",
    "10": "3.16",
    "13": "3.16",
    "14": "3.16",
    "15": "3.16",
    "16": "3.16"
  }, 
  "tubDiverterSpout": {
    "4": "5.62",
    "5": "5.62",
    "6": "5.62",
    "8": "5.62",
    "9": "5.62",
    "10": "5.62",
    "13": "5.62",
    "14": "5.62",
    "15": "5.62",
    "16": "5.62"
  },
  "thermostaticShowerValve": {
    "4": "1.63",
    "5": "1.63",
    "6": "1.63",
    "8": "1.63",
    "9": "1.63",
    "10": "1.63",
    "13": "1.63",
    "14": "1.63",
    "15": "1.63",
    "16": "1.63"
  },
  "hefauEarlyReplace": {
    "4": "27.90",
    "5": "31.70",
    "6": "10.90",
    "8": "10.40",
    "9": "18.60",
    "10": "25.80",
    "13": "23.80",
    "14": "28.90",
    "15": "13.90",
    "16": "32.50"
  },
  "hefauOnBurnout": {
    "4": "24.60",
    "5": "28.00",
    "6": "9.61",
    "8": "9.18",
    "9": "16.40",
    "10": "22.80",
    "13": "21.00",
    "14": "25.50",
    "15": "12.30",
    "16": "28.70"
  },
  "wallFurnaceEarlyReplace": {
    "4": "29.02",
    "5": "31.98",
    "6": "29.29",
    "8": "29.02",
    "9": "31.71",
    "10": "29.27",
    "13": "35.39",
    "14": "39.78",
    "15": "28.18",
    "16": "44.81"
  },
  "wallFurnaceOnBurnout": {
    "4": "21.80",
    "5": "24.00",
    "6": "22.00",
    "8": "21.80",
    "9": "23.80",
    "10": "22.00",
    "13": "26.60",
    "14": "29.90",
    "15": "21.20",
    "16": "33.70"
  },
  "solarWaterHeating": {
    "4": "64.75",
    "5": "64.75",
    "6": "64.75",
    "8": "64.75",
    "9": "64.75",
    "10": "64.75",
    "13": "64.75",
    "14": "64.75",
    "15": "64.75",
    "16": "64.75"
  },
  "co&SmokeAlarms": {
    "4": "0",
    "5": "0",
    "6": "0",
    "8": "0",
    "9": "0",
    "10": "0",
    "13": "0",
    "14": "0",
    "15": "0",
    "16": "0"
  },
  "smartThermostat": {
    "4": "9.90",
    "5": "0",
    "6": "6.58",
    "8": "6.60",
    "9": "3.33",
    "10": "7.86",
    "13": "6.26",
    "14": "4.70",
    "15": "6.36",
    "16": "8.24"
  },
  "storageWaterHeater": {
    "4": "26.1",
    "5": "26.1",
    "6": "26.1",
    "8": "26.1",
    "9": "26.1",
    "10": "26.1",
    "13": "26.1",
    "14": "26.1",
    "15": "26.1",
    "16": "26.1"
  },
  "tanklessWaterHeater81": {
    "4": "77.4",
    "5": "77.4",
    "6": "77.4",
    "8": "77.4",
    "9": "77.4",
    "10": "77.4",
    "13": "77.4",
    "14": "77.4",
    "15": "77.4",
    "16": "77.4"
  },
  "tanklessWaterHeater87": {
    "4": "91.2",
    "5": "91.2",
    "6": "91.2",
    "8": "91.2",
    "9": "91.2",
    "10": "91.2",
    "13": "91.2",
    "14": "91.2",
    "15": "91.2",
    "16": "91.2"
  },
  "tanklessWaterHeater95": {
    "4": "95.5",
    "5": "95.5",
    "6": "95.5",
    "8": "95.5",
    "9": "95.5",
    "10": "95.5",
    "13": "95.5",
    "14": "95.5",
    "15": "95.5",
    "16": "95.5"
  },
  "solarThermalWaterHeatingWStorage": {
    "4": "97.16",
    "5": "97.16",
    "6": "97.16",
    "8": "97.16",
    "9": "97.16",
    "10": "97.16",
    "13": "97.16",
    "14": "97.16",
    "15": "97.16",
    "16": "97.16"
  },
  "solarThermalWaterHeatingWTankless": {
    "4": "168.54",
    "5": "168.54",
    "6": "168.54",
    "8": "168.54",
    "9": "168.54",
    "10": "168.54",
    "13": "168.54",
    "14": "168.54",
    "15": "168.54",
    "16": "168.54"
  },
  "centralFurnace92": {
    "4": "18.59",
    "5": "18.59",
    "6": "18.59",
    "8": "18.59",
    "9": "18.59",
    "10": "18.59",
    "13": "18.59",
    "14": "18.59",
    "15": "18.59",
    "16": "18.59"
  },
  "centralFurnace95": {
    "4": "23.31",
    "5": "23.31",
    "6": "23.31",
    "8": "23.31",
    "9": "23.31",
    "10": "23.31",
    "13": "23.31",
    "14": "23.31",
    "15": "23.31",
    "16": "23.31"
  },
  "centralFurnace97": {
    "4": "26.33",
    "5": "26.33",
    "6": "26.33",
    "8": "26.33",
    "9": "26.33",
    "10": "26.33",
    "13": "26.33",
    "14": "26.33",
    "15": "26.33",
    "16": "26.33"
  },
  "tanklessWaterHeater": {
    "4": "95.50",
    "5": "101.00",
    "6": "92.80",
    "8": "89.80",
    "9": "90.50",
    "10": "90.10",
    "13": "89.70",
    "14": "92.60",
    "15": "76.50",
    "16": "107.00"
  }
};

module.exports = { thermValues, formDefaults };