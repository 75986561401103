import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, TextField, Button } from "@material-ui/core";
import PageLayout from 'components/PageLayout';
import useAPI from 'hooks/useAPI';
import useGlobalLoading from 'hooks/useGlobalLoading';
import dayjs from 'dayjs';

function ThermReport() {
  const api = useAPI();
  const { setIsLoading } = useGlobalLoading();

  const formatCSV = (thermInfo) => {
    console.log(thermInfo)
    let csv = "Enrollment Number,Therms,Technician,Date Uploaded\n";
    Object.values(thermInfo).forEach((calculation) => {
      let formattedDate;

      if (calculation.dateUploaded !== 'NA') {
        formattedDate = dayjs(calculation.dateUploaded).format('MM/DD/YYYY');
      }
      else {
        formattedDate = 'NA';
      };

      csv += `${calculation.enrollmentNumber},${calculation.totalTherms},${calculation.technician},${formattedDate}\n`;
    });
    return csv;
  };

  const downloadCSV = (csv) => {
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "therm-report.csv";
    link.click();
  }

  const handleSubmit = () => {
    setIsLoading(true)
    api
      .get("/submissions/calculate-therms")
      .then(res => {
        console.log(res.data)
        const csv = formatCSV(res.data.thermInfo);
        downloadCSV(csv)
      })
      .catch(err => {
        console.log(err)
        alert(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <PageLayout title='Run a report on uploaded forms'>
      <Box padding='25px'>
        <button onClick={handleSubmit}>Run</button>
      </Box>
    </PageLayout>
  )
}

export default ThermReport;