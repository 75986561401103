import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, TextField, Button } from "@material-ui/core";
import PageLayout from 'components/PageLayout';
import { thermValues, formDefaults } from './therms';
import { makeStyles } from '@material-ui/core';
import logo from "./remco.png";

const useStyles = makeStyles(theme => ({
  checkbox: {
    width: '20px',
    height: '20px'
  },
  dropDown: {
    width: '40px',
    height: '25px'
  },
  label: {
    fontSize: '20'
  },
  logo: {
    width: '8rem',
    height: 'auto',
    flex: '0 1 auto'
  },
  calculation: {
    padding: '0 10px 0 0',
    flex: '1 1 75%'
  },
  dropLabel: {
    padding: '10px 0 10px 0'
  }
}))

function ThermCalculator() {
  const values = [ '', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];
  const styles = useStyles();
  const [isClimateZoneUndefined, setIsClimateZoneUndefined] = useState(true);
  const [thermSavings, setThermSavings] = useState('0');
  const [tubSpoutMultiplier, setTubSpoutMultiplier] = useState('0');
  const [tsvMultiplier, setTSVMultiplier] = useState('0');
  const [climateZone, setClimateZone] = useState('');
  const { register, handleSubmit, errors, reset } = useForm();
  const climateZones = [ 
    '',
    '4', '5', '6', '8',
    '9', '10', '13', '14',
    '15', '16'
  ];

  const printFunction = data => {
    console.log(data)
  };

  const roundToHundredth = (equation) => {
    const roundedNum = Math.round(100*(equation)) / 100;
    return roundedNum;
  }

  const handleQuantityBasedMeasures = (event) => {

    let newMultiplier = Number(event.target.value);

    if (!(event.target.value)) newMultiplier = 0

    if (event.target.name === 'tubDiverterSpout') {
      const newAddedAmount = roundToHundredth(Number(newMultiplier) * 5.62)
      const amountToBeSubtracted = roundToHundredth(Number(tubSpoutMultiplier) * 5.62 * -1);
      const intThermSavings = Number(thermSavings);
      const newThermSavings = roundToHundredth(intThermSavings + newAddedAmount + amountToBeSubtracted);
      console.log({ tubSpoutMultiplier, newMultiplier })
      console.log(`${intThermSavings} + ${newAddedAmount} + ${amountToBeSubtracted} = ${newThermSavings}`)

      setTubSpoutMultiplier(event.target.value);
      setThermSavings(newThermSavings.toString());
    }

    else if (event.target.name === 'thermostaticShowerValve') {
      const newAddedAmount = roundToHundredth(Number(newMultiplier) * 1.63)
      const amountToBeSubtracted = roundToHundredth(Number(tsvMultiplier) * 1.63 * -1);
      const intThermSavings = Number(thermSavings);
      const newThermSavings = roundToHundredth(intThermSavings + newAddedAmount + amountToBeSubtracted);
      console.log({ tubSpoutMultiplier, newMultiplier })
      console.log(`${intThermSavings} + ${newAddedAmount} + ${amountToBeSubtracted} = ${newThermSavings}`)

      setTSVMultiplier(event.target.value);
      setThermSavings(newThermSavings.toString());
    };
  };
  
  const updateTherms = event => {

    if (event.target.name === 'tubDiverterSpout' || event.target.name === 'thermostaticShowerValve') {
      handleQuantityBasedMeasures(event);
      return;
    }

    const value = event.target.checked;
    console.log(value)
    const intThermSavings = Number(thermSavings);
    const intChangedMeasure = Number(thermValues[event.target.name][climateZone]);

    if (value) {
      const newThermSavings = Math.round(100*(intThermSavings + intChangedMeasure)) / 100;
      setThermSavings(newThermSavings.toString());
    }
    else {
      const newThermSavings = Math.round(100*(intThermSavings - intChangedMeasure)) / 100;
      setThermSavings(newThermSavings.toString());
    };
  };

  return (
    <PageLayout title='Therm Calculator'>
      <Box display='flex' padding='10px 0px 10px 0px' flexDirection='row' justifyContent='flex-start'>
        <Typography className={styles.calculation} variant='h4'>
          Total Therms Saved: {thermSavings}
        </Typography>
        <img className={styles.logo} src={logo} alt="Logo" />
      </Box>
      <form onSubmit={handleSubmit(printFunction)}>
        <Box flexDirection='column' padding='10px 0px 10px 0px'>
          <Typography className={styles.dropLabel}>
            Please Select a Climate Zone
          </Typography>
          <select
            isClearable
            inputRef={register}
            value={climateZone}
            onChange={(event) => {
              setClimateZone(event.target.value)
              setIsClimateZoneUndefined(!(event.target.value))
              reset(formDefaults)
              setTSVMultiplier('0')
              setTubSpoutMultiplier('0')
              setThermSavings('0')
            }}
            placeholder='Climate Zone'
          >
            {!!climateZones && climateZones.map(zone => (
              <option value={zone}>{zone}</option>
            ))}
          </select>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='airSealingEnvelope' name='airSealingEnvelope' />
            <label className={styles.label} for='airSealingEnvelope'>Air Sealing / Envelope</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='atticInsulation' name='atticInsulation' />
            <label className={styles.label} for='atticInsulation'>Attic Insulation</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='furnaceC&T' name='furnaceC&T' />
            <label className={styles.label} for='furnaceC&T'>Furnace Clean & Tune</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='furnaceRepair' name='furnaceRepair' />
            <label className={styles.label} for='furnaceRepair'>Furnace Repair</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='prescriptiveDuctSealing' name='prescriptiveDuctSealing' />
            <label className={styles.label} for='prescriptiveDuctSealing'>Prescriptive Duct Sealing</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='washers' name='washers' />
            <label className={styles.label} for='washers'>High Efficiency Clothes Washers</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='tankAndPipeInsulation' name='tankAndPipeInsulation' />
            <label className={styles.label} for='tankAndPipeInsulation'>Tank and Pipe Insulation</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='waterHeaterRepairReplace' name='waterHeaterRepairReplace' />
            <label className={styles.label} for='waterHeaterRepairReplace'>Water Heater Repair/Replace</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='otherHotWater' name='otherHotWater' />
            <label className={styles.label} for='otherHotWater'>Other Hot Water (Showerheads and/or Aerators)</label>
          </Box>
          <Box padding='10px 0px'>
            <select className={styles.dropDown} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='tubDiverterSpout' name='tubDiverterSpout'>
              {values.map(value => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
            <label className={styles.label} for='tubDiverterSpout'>Tub Diverter/Tub Spout</label>
          </Box>
          <Box padding='10px 0px'>
            <select className={styles.dropDown} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='thermostaticShowerValve' name='thermostaticShowerValve'>
              {values.map(value => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
            <label className={styles.label} for='thermostaticShowerValve'>Thermostatic Shower Valve</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='hefauEarlyReplace' name='hefauEarlyReplace' />
            <label className={styles.label} for='hefauEarlyReplace'>HEFAU Early Replace</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='hefauOnBurnout' name='hefauOnBurnout' />
            <label className={styles.label} for='hefauOnBurnout'>HEFAU on Burnout</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='wallFurnaceEarlyReplace' name='wallFurnaceEarlyReplace' />
            <label className={styles.label} for='wallFurnaceEarlyReplace'>Wall Furnace Early Replace</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='wallFurnaceOnBurnout' name='wallFurnaceOnBurnout' />
            <label className={styles.label} for='wallFurnaceOnBurnout'>Wall Furnace on Burnout</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='solarWaterHeating' name='solarWaterHeating' />
            <label className={styles.label} for='solarWaterHeating'>Solar Water Heating</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='co&SmokeAlarms' name='co&SmokeAlarms' />
            <label className={styles.label} for='co&SmokeAlarms'>CO & Smoke Alarms</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='smartThermostat' name='smartThermostat' />
            <label className={styles.label} for='smartThermostat'>Smart Thermostat</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='storageWaterHeater' name='storageWaterHeater' />
            <label className={styles.label} for='storageWaterHeater'>Storage Water Heater (UEF&gt;=0.64)</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='centralFurnace92' name='centralFurnace92' />
            <label className={styles.label} for='centralFurnace92'>Central Furnace (AFUE 92)</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='centralFurnace95' name='centralFurnace95' />
            <label className={styles.label} for='centralFurnace95'>Central Furnace (AFUE 95)</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='centralFurnace97' name='centralFurnace97' />
            <label className={styles.label} for='centralFurnace97'>Central Furnace (AFUE 97)</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='tanklessWaterHeater' name='tanklessWaterHeater' />
            <label className={styles.label} for='tanklessWaterHeater'>Tankless Water Heater</label>
          </Box>
          <Box padding='10px 0px'>
            <input className={styles.checkbox} disabled={isClimateZoneUndefined} onChange={updateTherms} ref={register} type='checkbox' id='smartFanController' name='smartFanController' />
            <label className={styles.label} for='tanklessWaterHeater'>Smart Fan Controller</label>
          </Box>
        </Box>
      </form>
      <Box padding='0px 0 20px 0' display='flex' justifyContent='flex-start'>

        <small>
          &copy; 2023 Reliable Energy Management Inc.
          <br/>
          License #704414
          <br />
          6829 Walthall Way, Paramount, CA 90723
        </small>

      </Box>
    </PageLayout>
  )
}

/**air sealing / envelope 
 * attic insulation -
 * furnace clean and tune -
 * furnace repair - 
 * prescriptive duct sealing -
 * high efficiency clothes washers - 
 * tank and pipe insulation - 
 * other hot water (aerators shwowerheads)-
 * tub diverter/tub spout - 
 * tsv - 
 * hefau early replace - 
 * hefau on burnout - 
 * wall furnace early replace - 
 * wall furnace on burnout - 
 * solar water healing - 
 * co & smoke alarms - 
 * smart thermostat - 
 * storage water heater - 
 * tankless water heater - 
 * solar thermal water heating w storage - 
 * solar thermal water heater w tankless 
 * central furnace 
 */

export default ThermCalculator;
