import { useState, useEffect } from 'react';
import useAPI from 'hooks/useAPI';

const useStatuses = () => {
  const [statuses, setStatuses] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const api = useAPI();

  useEffect(() => {
    api.get('/simple-resources/statuses')
      .then(res => {
        setStatuses(res.data.statuses)
        if (res.data.statuses && res.data.statuses.length > 0) {
          setStatusOptions(res.data.statuses.map(status => ({ value: status._id, label: status._id })));
        }
      })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { statuses, statusOptions };
}

export default useStatuses;