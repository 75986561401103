import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DayJS from 'react-dayjs';
import useUser from 'hooks/useUser';

const useStyles = makeStyles({
  box: {
    background: '#f9f9f9',
  },
  separator: {
    width: '70%',
    height: 2,
    backgroundColor: '#B7B7B7',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

function AppointmentCard(props) {
  const { appointment, cancelJob } = props; // from parent
  const { enrollmentNumber } = useParams();
  const { user } = useUser();
  const history = useHistory();
  const styles = useStyles();

  const viewAppointment = () => {
    history.push(`/customers/${enrollmentNumber}/appointment/${appointment._id}`)
  }

  const reassignJob = () => {
    history.push(`/customers/${enrollmentNumber}/appointment/${appointment._id}/reassign`)
  }
  
  if (!appointment) return null;
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box mb={1}>
            <h6>Time and Date</h6>
            <Typography>
              <DayJS format="MM/DD/YYYY">{appointment?.start}</DayJS>
            </Typography>
            <Typography>
              <DayJS format="hh:mm a">{appointment?.start}</DayJS> - <DayJS format="hh:mm a">{appointment?.end}</DayJS>
            </Typography>
          </Box>
          <Box mb={1}>
            <h6>Status</h6>
            <Typography>{appointment?.status || ''}</Typography>
          </Box>
          <Box mb={1}>
            <h6>Technician</h6>
            <Typography>{`${appointment?.technician?.firstName} ${appointment?.technician?.lastName}`}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Box mb={1}>
              <Button 
                color="primary" 
                variant="contained" 
                className="w-full" 
                onClick={viewAppointment} 
                disabled={!(user.role === 'auditor.initial' || user.role === 'auditor.final' || user.role === 'auditor' || user.role === 'admin')}
                >
                View Forms
              </Button>
            </Box>
          </Box>
          <Button color="primary" variant="contained" onClick={reassignJob}>Reschedule Job</Button>
          <Button color="secondary" variant="contained" onClick={cancelJob}>Cancel Job</Button>
        </Box>
      </Box>
      <Box my={3}>
        <div className={styles.separator}></div>
      </Box>
    </>
    );
}

export default AppointmentCard;