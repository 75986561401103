import axios from 'axios';
import qs from 'qs'; 

const BASE_URL = process.env.REACT_APP_BASE_URL;

function axiosWithAuth({ onSuccess, onError }={}) {
  const accessToken = localStorage.getItem('accessToken');
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    paramsSerializer: params => qs.stringify(params)
  })

  instance.interceptors.response.use(async (response) => {
    if (onSuccess) {
      await onSuccess(response);
    }
    return response;
  }, async (error) => {
    if (onError) {
      await onError(error)
    }
    return Promise.reject(error);
  })

  return instance;
}

export default axiosWithAuth;