import React, { useState, useEffect } from 'react';
import useAPI from 'hooks/useAPI';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Select from 'react-select';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PageLayout from 'components/PageLayout/PageLayout';
import useAccounts from 'hooks/useAccounts';
import PrivateRoute from 'components/routing/PrivateRoute';
import ListAccounts from './ListAccounts'
import StyledCard from 'components/StyledCard';
import InlineFormElement from 'components/InlineFormElement';
import BackButton from 'components/BackButton';
import { getPersonName, isSuccessfulResponse, ROLE_OPTIONS } from 'utils/helper';
import { useForm } from 'react-hook-form';
import useAlertDialog from 'components/AlertDialog'
import useGlobalLoading from 'hooks/useGlobalLoading';

function ManageUser() {
  const { setIsLoading } = useGlobalLoading();
  const api = useAPI();
  const { url } = useRouteMatch();
  const { userId } = useParams();
  const { ownedAccounts } = useAccounts(userId);
  const [user, setUser] = useState();
  const { handleSubmit, register, reset } = useForm();
  const [role, setRole] = useState();
  const history = useHistory();
  const {
    AlertDialogComponent,
    setOpen,
    setTitle,
    setDescription,
    open
  } = useAlertDialog();

  useEffect(() => {
    initUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initUser = async () => {
    try {
      const res = await api.get(`/users/${userId}`)
      if (isSuccessfulResponse(res)) {
        console.log("response", res);
        setUser(res.data.user)
        reset(res.data.user)
        const userRole = ROLE_OPTIONS.find(option => option.value === res.data.user.role);
        setRole(userRole);
      }
    } catch(err) {
      console.log(err)
    }
  }

  const resetPassword = () => {
    setIsLoading(true)
    api
      .put(`/users/${userId}/reset-password`)
      .then(res => {
        setTitle('Password successfully reset')
        setDescription(`Temporary password: ${res.data.password}`)
        setOpen(true)
        console.log(res)
      })
      .catch(err => {
        setOpen(true)
        setTitle('Error')
        setDescription(`Server Message: ${err.message}`)
        console.log(err)
      })
      .finally(() => setIsLoading(false));
  }

  const updateUser = data => {
    setIsLoading(true)
    api
      .put(`/users/${userId}`, data)
      .then(res => {
        setTitle('Update User')
        setDescription(res.data.message)
        setOpen(true)
        console.log(res)
      })
      .catch(err => {
        setOpen(true)
        setTitle('Error')
        setDescription(`Server Message: ${err.message}`)
        console.log(err)
      })
      .finally(() => setIsLoading(false));
  }


  return (
    <PageLayout title={getPersonName(user)}>
      <AlertDialogComponent open={open}/>
      <Box padding={1}>
        <Button 
          variant="contained" 
          onClick={resetPassword}
          color="primary"
        >
          <p>Reset Password</p>
        </Button>
      </Box>
      <Box width="20%" mt={5}>
        <form onSubmit={handleSubmit(updateUser)}>
          <StyledCard>
            <InlineFormElement label='First Name'>
              <TextField name="firstName" variant="outlined" size="small" inputRef={register} className='w-full' />
            </InlineFormElement>
            <InlineFormElement label='Last Name'>
              <TextField name="lastName" variant="outlined" size="small" inputRef={register} className='w-full' />
            </InlineFormElement>
            <InlineFormElement label='Username'>
              <TextField name="username" variant="outlined" size="small" inputRef={register} className='w-full' />
            </InlineFormElement>
            <InlineFormElement label='Role'>
              <Select
                isClearable
                options={ROLE_OPTIONS}
                value={role}
                onChange={(value) => setRole(value)}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </InlineFormElement>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button 
                variant="contained"
                color="primary"
                type='submit'
                onClick={handleSubmit}
              >
                Update
              </Button>
              <BackButton url='/users' ml={1} />
            </Box>
          </StyledCard>
        </form>
      </Box>
      <Button 
        variant="outlined" 
        onClick={() => history.push(`${userId}/accounts`)}
        color="primary"
      >
        <p>Show Accounts</p>
      </Button>
      <PrivateRoute path={`${url}/accounts`}>
        <Box mt={5}>
          <ListAccounts ownedAccounts={ownedAccounts}/>
        </Box>
      </PrivateRoute>
    </PageLayout>
  )
}

export default ManageUser;