import React from 'react';
import { useState, useEffect, useMemo, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import CreatableSelect from "react-select/creatable";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { format } from 'date-fns';
import useResizablePage from "../../hooks/useResizablePage";
import Checkbox from "../Checkbox";
import Input from "../Input";
import SignatureModal from "../SignatureModal";
import useAPI from "../../hooks/useAPI";
import useUser from "../../hooks/useUser";
import useCustomer from 'hooks/useCustomer';
import { SecondaryButton } from "../CustomButton";
import {
  generateDefaultValues,
  generateRefs,
  generateErrorMessages,
} from "../../utils/form-tools";

import useGlobalLoading from 'hooks/useGlobalLoading';

const useStyles = makeStyles({
  caption: {
    color: '#959595'
  }
})

const MobileForm = ({
  type,
  version,
  fields,
  useValidation = () => ({}),
  background,
  width,
  height,
  formId
}) => {

  const { setIsLoading } = useGlobalLoading();
  const { enrollmentNumber, appointmentId, submissionId } = useParams();
  const currentURL = useLocation();
  const URLString = currentURL.pathname;
  const isWasher = URLString.includes("washer");
  console.log({ isWasher });
  const { user } = useUser();
  const { customer } = useCustomer(enrollmentNumber);
  console.log(user.role)

  const defaultValues = generateDefaultValues(fields);
  const signatureRefs = generateRefs(fields);
  const api = useAPI();
  const styles = useStyles();

  const {
    register,
    handleSubmit,
    reset,
    control,
    errors,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
  });

  const { page } = useResizablePage({ width, height });
  const logic = useValidation(control);
  const history = useHistory();
  const [submission, setSubmission] = useState({});
  const [activeRevision, setActiveRevision] = useState('');
  const [activeRevisionAuthor, setActiveRevisionAuthor] = useState('');
  const [revisions, setRevisions] = useState([]);
  const [modalSignatureFieldName, setModalSignatureFieldName] = useState(null);

  const revisionsLookup = useMemo(() => revisions.reduce((lookup, revision) => {
    lookup[revision._id] = revision;
    return lookup;
  }, {}), [revisions])

  // TODO: review should only validate form, not submit anything 
  useEffect(() => {
    api
      .get(`/forms/${appointmentId}/submission/${submissionId}`)
      .then(res => {
        setActiveRevision(res.data.submission.currentRevision._id);
        setActiveRevisionAuthor(res.data.submission.currentRevision.createdBy);
        setRevisions(res.data.revisions);
      })
      .catch(err => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const signatureFields = Object.keys(fields).filter(
      (key) => fields[key].needsRef
    );
    
    for (const field of signatureFields) {
      if (signatureRefs[field]) {
        const signatureFieldValue = getValues(field);
        if (signatureFieldValue)
          signatureRefs[field].src = signatureFieldValue;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, user]);

  // alert errors on validation
  useEffect(() => {
    if (Object.keys(errors).length) {
      const errorMessages = generateErrorMessages(errors);
      alert(errorMessages.join("\n"));
      clearErrors();
    }
  }, [errors, clearErrors]);

  useEffect(() => {
    if (activeRevision && Object.keys(revisionsLookup).length) {
      const fields = revisionsLookup[activeRevision]?.fields;
      console.log({
        activeRevision,
        revisionsLookup,
        fields
      })
      reset(transformFields(fields));
      if (revisionsLookup[activeRevision]?.createdBy) {
        api
          .get(`/users/${revisionsLookup[activeRevision]?.createdBy}`)
          .then(res => {
            setActiveRevisionAuthor(res.data.user)
          })
          .catch(err => console.log(err))
      }
    }
  }, [activeRevision, revisionsLookup])

  useEffect(() => {
    api
      .get(`/submissions/${submissionId}`)
      .then(res => {
        setSubmission(res.data.submission)
      })
      .catch(err => console.log(err.message))
  }, [])

  const transformFields = fields => fields.reduce((fields, [name, value]) => {
    fields[name] = value;
    return fields;
  }, {})

  const postRevision = formFields => {
    setIsLoading(true)
    api
      .post(`/forms/${appointmentId}/revision`, {
        fields: formFields,
        submission: submissionId,
        previous: activeRevision
      })
      .then(res => {
        toast.success('Successfully submitted and changed current revision.')
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false));
  }

  const verifySubmission = () => {
    return
  }

  const readyForUpload = () => {
    setIsLoading(true);

    const data = { status: 'pending:upload' }

    api
      .put(`/submissions/${submissionId}/update-status`, data)
      .then(res => {
        console.log(res.data)
        toast.success("Submisssion is now ready for upload and data entry.")
        setSubmission(res.data.submission)
      })
      .catch(err => console.log(err.message))
      .then(() => setIsLoading(false));
  }

  const readyForFinalAudit = () => {
    setIsLoading(true);

    const data = { status: 'pending:finalAudit' }

    api 
      .put(`/submissions/${submissionId}/update-status`, data)
      .then(res => {
        console.log(res.data)
        toast.success("Submisssion is now ready for final auditing.")
        setSubmission(res.data.submission)
      })
      .catch(err => console.log(err.message))
      .then(() => setIsLoading(false));
  }
  
  const renderValue = (value, options) => {
    const val = value === "" ? 
      null : 
      options.find((obj) => obj.value === value);
    return val || {value : value, label: value};
  };

  const uploadAndPreprocessWasher = async () => {
    const dateUploadedObj = new Date();
    const dateUploaded = dateUploadedObj.toISOString();

    let isUploadSuccessful
    let isPreprocessingSuccessful

    setIsLoading(true);

    const data = {
      isWasher: true,
      submissionId,
      enrollmentNumber,
      customerLastName: customer.lastName,
      utilityAccountId: localStorage.getItem("accountId"),
      userId: user._id,
      dateUploaded 
    }
    console.log(data)

    if (window.confirm("Are you sure you want to perform upload and data entry for this washer form? This is not reversible.")) {
      await api
        .post(`/heat/washer-upload`, data)
        .then(res => {
          toast.success("PDF has been uploaded to HEAT, attempting data entry...")
          isUploadSuccessful = true;
        })
        .catch(err => {
          isUploadSuccessful = false;
          setIsLoading(false)
          toast.error(err.message)
        })

      if (isUploadSuccessful) {
        await api
          .post(`/heat/washer-preprocess`, data)
          .then(res => {
            isPreprocessingSuccessful = true;
            toast.success("Data entry complete!")
            setIsLoading(false)
          })
          .catch(err => {
            isPreprocessingSuccessful = false;
            setIsLoading(false)
            toast.error(err.message)
          })
        }
    }

    if (isUploadSuccessful && isPreprocessingSuccessful) {
      api
        .put(`/appointments/${appointmentId}/update-status`, { status: "pending:verification", dateUploaded: dateUploaded })
        .then(res => {
          toast.success("Appointment and customer status set to pending verification.")
        })
        .catch(err => {
          toast.error(err.message)
        });
    }
  };

  return (
    <>
      <Box p={2} container display="flex">
        <Box flex="1">
          <Box display="flex" my={1}>
            <Box mr={1} width="60%">
              <Button onClick={() => history.push(`/customers/${enrollmentNumber}/appointment/${appointmentId}`)} color="primary" variant="contained" fullWidth>← RETURN TO APPOINTMENT</Button>
            </Box>
            <Box flex={1}></Box>
          </Box>
          <Box width="70%">
            <FormControl fullWidth>
              <InputLabel id='revision-select-label' >Currently Viewing</InputLabel>
              <Select
                id='revision-select'
                labelId='revision-select-label'
                value={activeRevision}
                onChange={(event) => setActiveRevision(event.target.value)}
             >
               <MenuItem value=''></MenuItem>
               {revisions?.map(revision => <MenuItem key={revision._id} value={revision._id}>{format(new Date(revision.createdAt), 'MM/dd/yyyy')}</MenuItem>)}
             </Select>
            </FormControl>
          </Box>
          <Box>
            Submitted by: {`${activeRevisionAuthor?.firstName} ${activeRevisionAuthor?.lastName}`}
          </Box>
        </Box>
        <Box flex="1">
          <Box my={1} display="flex">
            <Box flex={1} mx={1}>
            </Box>
            <Box flex={1} mx={1}>
              {(isWasher || user.role === 'auditor.final' || user.role === 'auditor') && (
                <SecondaryButton onClick={uploadAndPreprocessWasher} variant="contained" fullWidth>Upload and Preprocess Washer</SecondaryButton>
              )}
            </Box>
          </Box>
          <Box my={1} display="flex">
            <Box flex={1} mx={1}>
            </Box>
            <Box flex={1} mx={1}>
              {(user.role ==='admin' || user.role === 'auditor.final' || user.role === 'auditor') && (
                <Button onClick={readyForUpload} color="primary" variant="contained" fullWidth>Ready for upload and data entry</Button>
              )}
            </Box>
          </Box>
          <Box my={1} display="flex">
            <Box flex={1} mx={1}>
            </Box>
            <Box flex={1} mx={1}>
              {(user.role ==='admin' || user.role === 'auditor.final' || user.role === 'auditor') && (
                <React.Fragment>
                  <Button onClick={verifySubmission} color="primary" variant="contained" disabled={submission?.status !== 'pending:verification'} fullWidth>Verify</Button>
                  {submission?.status !== 'pending:verification' && (
                    <Typography variant="caption" className={styles.caption}>* Becomes available once PDFs have been uploaded and data entered.</Typography>
                  )}
                </React.Fragment>
              )}
              {(user.role === 'auditor.initial') && (
                <React.Fragment>
                  <Button onClick={readyForFinalAudit} color="primary" variant="contained" fullWidth>Ready for final audit</Button>
                  {(submission?.status === 'pending:finalAudit') && (
                    <Typography variant="caption" className={styles.caption}>* Form has been marked ready for final audit with the current active revision.</Typography>
                  )}
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <form
        style={{
          position: "relative",
          width: page.width,
          height: page.height,
          backgroundImage: `url(${background})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        onSubmit={handleSubmit(postRevision)}
      >
        <Box flex={1} mx={1} width='30px' height='10px'>
          <Button onClick={handleSubmit(postRevision)} disabled={(user.role === 'scheduler')} color='primary' variant='contained' fullWidth type='submit'>Save</Button>
        </Box>
        {/* eslint-disable-next-line array-callback-return */}
        {Object.values(fields).map((field) => {
          const style = {
            position: "absolute",
            top: field.yMod * page.height,
            left: field.xMod * page.width,
            height: field.heightMod * page.height,
            width: field.widthMod * page.width,
            outline: "none",
            border: "none",
            background: "none",
            fontSize: "1.1rem",
          };

          // eslint-disable-next-line default-case
          switch (field.type) {
            case "text":
              return (
                <Input
                  key={field.name}
                  name={field.name}
                  field={field}
                  page={page}
                  control={control}
                  disabled={(user.role === 'scheduler')} 
                  ref={(ref) => {
                    if (field.name in logic) {
                      register(ref, logic[field.name]());
                    } else {
                      register(ref);
                    }
                  }}
                />
              );
              case "number":
                return (
                  <Input
                    key={field.name}
                    name={field.name}
                    field={field}
                    page={page}
                    type="number"
                    control={control}
                    disabled={(user.role === 'scheduler')} 
                    ref={(ref) => {
                      if (field.name in logic) {
                        register(ref, logic[field.name]());
                      } else {
                        register(ref);
                      }
                    }}
                  />
                );
            case "textarea":
              return (
                <textarea
                  key={field.name}
                  id={field.name}
                  name={field.name}
                  disabled={(user.role === 'scheduler')} 
                  ref={(ref) => {
                    if (field.name in logic) {
                      register(ref, logic[field.name]());
                    } else {
                      register(ref);
                    }
                  }}
                  style={{...style, lineHeight: field.lineHeight }}
                  control={control}
                  {...style}
                />
              );
            case "select":
              return (
                <select
                  key={field.name}
                  style={style}
                  name={field.name}
                  disabled={(user.role === 'scheduler')} 
                  ref={(ref) => {
                    if (field.name in logic) {
                      register(ref, logic[field.name]());
                    } else {
                      register(ref);
                    }
                  }}
                >
                  <option></option>
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              );
              case "expandableSelect":
                return (
                  <Controller
                    name={field.name}
                    control={control}
                    defaultValue=''
                    key={field.name}
                    disabled={(user.role === 'scheduler')} 
                    render={({ onChange, value, ref }) => {
                      return <CreatableSelect
                        disabled={(user.role === 'scheduler')} 
                        styles={{
                          container: () => style,
                          control: (provided) => ({
                            ...provided,
                            borderWidth: 0
                          })
                        }}
                        inputRef={ref}
                        placeholder=""
                        name={field.name}
                        value={renderValue(value, field.options)}
                        onChange={(newValue) => {
                          if (newValue) {
                            setValue(field.name, newValue.value);
                            onChange(newValue.value)
                          }
                        }}
                        isClearable
                        options={field.options}
                      />
                    }}
                  />
                
                );
          case "checkbox":
              return (
                <Fragment key={field.name}>
                  <label
                    htmlFor={field.name}
                    style={{
                      position: "absolute",
                      width: field.label.widthMod * page.width,
                      height: field.label.heightMod * page.height,
                      left: field.label.xMod * page.width,
                      top: field.label.yMod * page.height,
                    }}
                  ></label>
                  <Checkbox
                    id={field.name}
                    name={field.name}
                    disabled={(user.role === 'scheduler')} 
                    ref={(ref) => {
                      if (field.name in logic) {
                        register(ref, logic[field.name]());
                      } else {
                        register(ref);
                      }
                    }}
                    control={control}
                    {...style}
                  />
                </Fragment>
              );
            case "signature":
              return (
                <div 
                  key={field.name}
                  style={{
                    position: "absolute",
                    width: field.widthMod * page.width,
                    height: field.heightMod * page.height,
                    left: field.xMod * page.width,
                    top: field.yMod * page.height,
                  }}>
                  <img 
                    ref={(ref) => { 
                      signatureRefs[field.name] = ref;
                    }}
                    alt={field.name}
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    onClick={() => {
                      if (user.role !== 'scheduler') setModalSignatureFieldName(field.name)
                    }}
                    />
                  <input 
                    id={field.name}
                    name={field.name}
                    ref={(ref) => { 
                      if (field.name in logic) {
                        register(ref, logic[field.name]());
                      } else {
                        register(ref);
                      }
                    }}
                    control={control}
                    type="hidden"
                    />
                </div>
              );
          }
        })}
      </form>
      {!!modalSignatureFieldName && (
        <SignatureModal
          fieldName={modalSignatureFieldName}
          setFieldName={setModalSignatureFieldName}
          setValue={setValue}
          getValues={getValues}
        />
      )}
    </>
  );
};

export default MobileForm;
