import React from 'react';
import CssBaseLine from '@material-ui/core/CssBaseline';
import Routes from './Routes';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar';
import Notifications from 'components/Notifications';
import useStyles from './styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const styles = useStyles();
  return (
    <CssBaseLine>
      <div className={styles.root}>
        <LoadingSpinner/>
        <NavBar/>
        <Notifications/>
        <Routes/>
        <ToastContainer />
      </div>
    </CssBaseLine>
  );
}

export default App;
