import xor from "../../../../../utils/xor";

const VALIDATION_MESSAGES = {
  'squareFootage_required': 'Home square footage is required',
  'attic.isChecked_checkOrNf': 'Attic Insultation requires to be checked OR NF code', 
  'caulking.wall.nf_nfAndChecks': 'Caulking Wall no checks allowed when NF selected.', 
  'caulking.window.nf_nfAndChecks': 'Caulking Window no checks allowed when NF selected.', 
  'caulking.door.nf_nfAndChecks': 'Caulking Door no checks allowed when NF selected.', 
  'squareFootage_numbers': 'Home square footage Numbers Only',
  'attic.isChecked_hasRValueIfChecked': 'Attic Insultation should have R value if it is checked', 
  'caulking.door.qty_qtyAndChecks': 'Please make sure the quantity and checkboxes are properly filled for Caulking Door.', 
  'caulking.wall.qty_qtyAndChecks': 'Please make sure the quantity and checkboxes are properly filled for Caulking Wall.', 
  'caulking.window.qty_qtyAndChecks': 'Please make sure the quantity and checkboxes are properly filled for Caulking Window.', 
  'aerator.bath.qty_qtyAndChecks': 'Faucet Aerator Bath quantity and checks.',
  "aerator.kitchen.qty_qtyAndChecks": 'Faucet Aerator Kitchen quantity and checks.',
  "furnaceC&T.qty_qtyAndChecks": 'Furnace Clean and Tune (C&T) quantity and checks',
  "showerhead.handheld.qty_qtyAndChecks": 'Low Flow Showerhead Handheld quantity and checks',
  "showerhead.regular.qty_qtyAndChecks": 'Low Flow Showerhead Regular quantity and checks',
  "waterHeaterInsulationBlanket.qty_individualOrCentral": 'Water Heater Insulatation (Blanket) Individual or Central',

  "smartThermostat.serial2_validate": 'Smart Thermostat Serial2 validate',
  "casingCaulkingDoor.unit_validate": 'Casing w/Caulking- Door unit validate',
  "casingCaulkingWindow.unit_validate": 'Casing w/Caulking- Window unit validate',
  "doorJambCaulking.unit_validate": 'Door Jamb w/Caulking unit validate',

  "smartThermostat.qty_etc": 'Smart Thermostat: Please make sure you filled out the NF code OR scanned the serial numbers and added a quanitity.',

  "doorSC:24-36.qty": 'Door S/C- 24, 28, 30, 32, 36 quantity',
  "doorSC:34-42.qty": 'Door S/C- 34, 42 quantity',
  "doorStopCaulking.qty": "Door Stop w/Caulking quantity",
  "glassRepl:DS-SS.qty": 'Glass Repl- DS/SS quantity',

  "doorStopCaulking.unit": "Door Stop w/Caulking Lin. Ft",
  "glassRepl:DS-SS.unit": 'Glass Repl- DS/SS unit',
  "glassReplLouvered.unit": 'Glass Repl- Louvered',
  "glassReplPolycarbonate.unit": 'Glass Repl- Polycarbonated',
  "glassReplSpecialty.unit": 'Glass Repl- Specialty',
  "glassReplTempered.unit": 'Glass Repl- Tempered',

  "waterPressure_numbers": 'Water Pressure must always have a number',
  "leftNoticeOfCondition_isChecked": "Left Notice of Unsatisfactory Condition You need to select an appliance",
  "leftNoticeOfCondition_toBeChecked": "Left Notice of Unsatisfactory Condition You need to check Left Notice Unsatisfactory Condition",
  "leftNoticeOfCondition_isOther": "Left Notice of Unsatisfactory Condition You need to checkmark the other box.",
  "leftNoticeOfCondition_OtherText": "Left Notice of Unsatisfactory Condition You need to type something in field next to other box",
  "programIdBadgeNumber_numbers": "Program ID Badge Number Numbers only",
  "programIdBadgeNumber_required": "Program ID Badge Number Numbers required",
  "customerName_required": "Customer name required",
  "customerSignature_required": "Customer signature required",
  "installerName_required": "Installer name required",
  "installerSignature_required": "Installer Signature required"
} 

const useValidation = (control) => {
  const { getValues } = control;

  const hasQtyOrNf = (measureName, nfField) => (value) => xor(!!value, !!getValues(nfField)) || `${measureName} requires a Qty OR NF code`;
  const nfEqualsZeroZero = (measureName) => value => {
    const additionalInfo = getValues('additionalInformation');
    return ((value !== '00' && value !== '0')) || (!!additionalInfo) || `${measureName} requires a comment since NF code is 00 or 0`;
  }

  const hasLocationIfQty = (measureName, locationField) => (value) => ((!!value && !!getValues(locationField)) || !value)  || `${measureName} requires location when there is any quantity value`;

  return {
    "": () => ({
    }),
    "acCoverClipOn.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('A/C Cover (Window/Wall) - Clip On'),
      },
    }),
    "acCoverMagnetic.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('A/C Cover (Window/Wall) - Magnetic'),
      },
    }),
    "attic.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Attic Insulation')
      },
    }),
    "acCoverClipOn.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("A/C Cover (Window/Wall)- Clip On", "acCoverClipOn.nf"),
        location: hasLocationIfQty("A/C Cover (Window/Wall)- Clip On", "acCoverClipOn.location") || 'Cover Clip On Location'
      },
    }),
    "acCoverMagnetic.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("A/C Cover (Window/Wall)- Magnetic", "acCoverMagnetic.nf"),
        location: hasLocationIfQty("A/C Cover (Window/Wall)- Magnetic", "acCoverMagnetic.location") || 'Cover Magnetic Location',
      },
    }),
    "attic.isChecked": () => ({
      validate: {
        checkOrNf: (isChecked) => xor(isChecked, !!getValues("attic.nf")) || VALIDATION_MESSAGES['attic.isChecked_checkOrNf'],
      },
    }),
    "caulking.door.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Caulking Door", "caulking.door.nf"),
        qtyAndChecks: (qty) => {
          const isDoor = getValues("caulking.door.isChecked");
          const isInterior = getValues("caulking.door.isInterior");
          const isExterior = getValues("caulking.door.isExterior");

          return ((!!qty && isDoor && (isInterior || isExterior)) || !qty) || VALIDATION_MESSAGES['caulking.door.qty_qtyAndChecks'];
        },
      },
    }),
    "caulking.door.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Caulking Door NF', 'caulking.door.nf'),
        nfAndChecks: (nf) => {
          const isDoor = getValues("caulking.door.isChecked");
          const isInterior = getValues("caulking.door.isInterior");
          const isExterior = getValues("caulking.door.isExterior");
          return ((!!nf && !(isDoor || isInterior || isExterior)) || !nf) || VALIDATION_MESSAGES['caulking.door.nf_nfAndChecks'];
        },
      },
    }),
    "caulking.wall.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Caulking Wall", "caulking.wall.nf"),
        qtyAndChecks: (qty) => {
          const isDoor = getValues("caulking.wall.isChecked");
          const isInterior = getValues("caulking.wall.isInterior");
          const isExterior = getValues("caulking.wall.isExterior");

          return ((!!qty && isDoor && (isInterior || isExterior)) || !qty) || VALIDATION_MESSAGES['caulking.wall.qty_qtyAndChecks'];
        },
      },
    }),
    "caulking.wall.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Caulking Wall NF'),
        nfAndChecks: (nf) => {
          const isDoor = getValues("caulking.wall.isChecked");
          const isInterior = getValues("caulking.wall.isInterior");
          const isExterior = getValues("caulking.wall.isExterior");
          return ((!!nf && !(isDoor || isInterior || isExterior)) || !nf) || VALIDATION_MESSAGES['caulking.wall.nf_nfAndChecks'];
        },
      },
    }),
    "caulking.window.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Caulking Window", "caulking.window.nf"),
        qtyAndChecks: (qty) => {
          const isDoor = getValues("caulking.window.isChecked");
          const isInterior = getValues("caulking.window.isInterior");
          const isExterior = getValues("caulking.window.isExterior");

          return ((!!qty && isDoor && (isInterior || isExterior)) || !qty) || VALIDATION_MESSAGES['caulking.window.qty_qtyAndChecks'];
        },
      },
    }),
    "caulking.window.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Caulking Window NF'),
        nfAndChecks: (nf) => {
          const isDoor = getValues("caulking.window.isChecked");
          const isInterior = getValues("caulking.window.isInterior");
          const isExterior = getValues("caulking.window.isExterior");
          return ((!!nf && !(isDoor || isInterior || isExterior)) || !nf) || VALIDATION_MESSAGES['caulking.window.nf_nfAndChecks'];
        },
      },
    }),
    "evapCoolerRegisterCoverClipOn.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Evaporative Cooler Register Cover- Clip On", "evapCoolerRegisterCoverClipOn.nf"),
        location: hasLocationIfQty("Evaporative Cooler Register Cover- Clip On", "evapCoolerRegisterCoverClipOn.location"),
      },
    }),
    "evapCoolerRegisterCoverClipOn.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Evaporative Cooler Register Cover- Clip On')
      }
    }),
    "evapCoolerRegisterCoverMagnetic.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Evaporative Cooler Register Cover- Magnetic", "evapCoolerRegisterCoverMagnetic.nf"),
        location: hasLocationIfQty("Evaporative Cooler Register Cover- Magnetic", "evapCoolerRegisterCoverMagnetic.location"),
      },
    }),
    "evapCoolerRegisterCoverMagnetic.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Evaporative Cooler Register Cover- Magnetic')
      }
    }),
    "aerator.bath.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Faucet Aerator Bath", "aerator.bath.nf"),
        qtyAndCheck: (qty) => (!!qty === getValues("aerator.bath.isChecked")) || VALIDATION_MESSAGES['aerator.bath.qty_qtyAndChecks'],
      },
    }),
    "aerator.bath.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Faucet Aerator - Bath')
      }
    }),
    "aerator.kitchen.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Faucet Aerator Kitchen", "aerator.kitchen.nf"),
        qtyAndCheck: (qty) => (!!qty === getValues("aerator.kitchen.isChecked")) || VALIDATION_MESSAGES['aerator.kitchen.qty_qtyAndChecks'],
      },
    }),
    "aerator.kitchen.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Faucet Aerator - Kitchen')
      }
    }),
    "furnaceC&T.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Furnace Clean and Tune (C&T)", "furnaceC&T.nf"),
        qtyAndCheck: (qty) => {
          const isWall = getValues("furnaceC&T.isWall");
          const isFloor = getValues("furnaceC&T.isFloor");
          const isFAU = getValues("furnaceC&T.isFAU");
          const isOther = getValues("furnaceC&T.isOther");

          return (!!qty === (isWall || isFloor || isFAU || isOther)) || VALIDATION_MESSAGES['furnaceC&T.qty_qtyAndChecks'];
        },
      },
    }),
    "furnaceC&T.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Furnace Clean and Tune (C&T)')
      }
    }),
    "showerhead.handheld.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Low Flow Showerhead Handheld", "showerhead.handheld.nf"),
        qtyAndCheck: (qty) => {
          const isHandheld = getValues("showerhead.handheld.isChecked");
          return (!!qty === isHandheld) || VALIDATION_MESSAGES['showerhead.handheld.qty_qtyAndChecks'];
        },
      },
    }),
    "showerhead.handheld.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Low Flow Showerhead - Handheld')
      }
    }),
    "showerhead.regular.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Low Flow Showerhead Regular", "showerhead.regular.nf"),
        qtyAndCheck: (qty) => {
          const isRegular = getValues("showerhead.regular.isChecked");
          return (!!qty === isRegular) || VALIDATION_MESSAGES['showerhead.regular.qty_qtyAndChecks'];
        },
      },
    }),
    "showerhead.regular.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Low Flow Showerhead - Regular')
      }
    }),
    "duct.nf": () => ({
      validate: (nf) => !!nf !== getValues("duct.isChecked") || 'Duct should be checked.',
      nfEqualsZeroZero: nfEqualsZeroZero('Prescriptive Duct Sealing')
    }),
    "sprKit.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Standing Pilot Retrofit Kit (S.P.R.)", "sprKit.nf"),
        location: hasLocationIfQty("Standing Pilot Retrofit Kit (S.P.R.)", "sprKit.location"),
      },
    }),
    "sprKit.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Standing Pilot Retrofit Kit (S.P.R.)')
      }
    }),
    "smartThermostat.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Smart Thermostat", "smartThermostat.nf"),
        etc: (qty) => {
          const make = getValues("smartThermostat.make");
          const model = getValues("smartThermostat.model");
          const serial1 = getValues("smartThermostat.serial1");

          return (((!!qty === !!make) === !!model) === !!serial1) || VALIDATION_MESSAGES['smartThermostat.qty_etc'];
        },
      },
    }),
    "smartThermostat.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Smart Thermostat')
      }
    }),
    "smartThermostat.serial2": () => ({
      validate: (serial2) => {
        const serial1 = getValues("smartThermostat.serial1");
        return (((!!serial1 && !!serial2) || !serial2) || '') || VALIDATION_MESSAGES['smartThermostat.serial2_validate'];
      },
    }),
    "switchOutletGasketCover.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Switch/Outlet Gaskets & Covers", "switchOutletGasketCover.nf"),
        location: hasLocationIfQty("Switch/Outlet Gaskets & Covers", "switchOutletGasketCover.location"),
      },
    }),
    "switchOutletGasketCover.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Switch/Outlet Gaskets & Covers')
      }
    }),
    "thermostaticShowerValve.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Thermostatic Shower Value", "thermostaticShowerValve.nf"),
        location: hasLocationIfQty("Thermostatic Shower Value", "thermostaticShowerValve.location"),
      },
    }),
    "thermostaticShowerValve.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Thermostatic Shower Valve')
      }
    }),
    "thermTubSpoutRegularShowerhead.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Thermostatic Tub Spout w/Regular Shower Head", "thermTubSpoutRegularShowerhead.nf"),
        location: hasLocationIfQty("Thermostatic Tub Spout w/Regular Shower Head", "thermTubSpoutRegularShowerhead.location"),
      },
    }),
    "thermTubSpoutRegularShowerhead.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Thermostatic Tub Spout w/Regular Shower Head')
      }
    }),
    "thermTubSpoutHandheldShowerhead.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Thermostatic Tub Spout w/Handheld Shower Head", "thermTubSpoutHandheldShowerhead.nf"),
        location: hasLocationIfQty("Thermostatic Tub Spout w/Handheld Shower Head", "thermTubSpoutHandheldShowerhead.location"),
      },
    }),
    "thermTubSpoutHandheldShowerhead.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Thermostatic Tub Spout w/Handheld Shower Head')
      }
    }),
    "waterHeaterInsulationBlanket.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Water Heater Insulation (Blanket)", "waterHeaterInsulationBlanket.nf"),
        individualOrCentral: () => {
          const isIndividual = getValues(
            "waterHeaterInsulationBlanket.isIndividual"
          );
          const isCentral = getValues("waterHeaterInsulationBlanket.isCentral");
          return !((isIndividual && isCentral) || (!isIndividual && !isCentral))  || VALIDATION_MESSAGES['waterHeaterInsulationBlanket.qty_individualOrCentral'];
        },
      },
    }),
    "waterHeaterInsulationBlanket.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Water Heater Insulation (Blanket)')
      }
    }),
    "waterHeaterPipeInsulation.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Water Heater Pipe Insulation (Wrap)", "waterHeaterPipeInsulation.nf"),
        location: hasLocationIfQty("Water Heater Pipe Insulation (Wrap)", "waterHeaterPipeInsulation.location"),
      },
    }),
    "waterHeaterPipeInsulation.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Water Heater Pipe Insulation (Wrap)')
      }
    }),
    "weatherstrippingAtticAccess.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Weatherstripping- Attic Access", "weatherstrippingAtticAccess.nf"),
        location: hasLocationIfQty("Weatherstripping- Attic Access", "weatherstrippingAtticAccess.location"),
      },
    }),
    "weatherstrippingAtticAccess.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Weatherstripping - Attic Access')
      }
    }),
    "weatherstrippingDoor.applDoorFoamTape.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Weatherstripping- Door Appl. Door- Foam Tape", "weatherstrippingDoor.applDoorFoamTape.nf"),
        location: hasLocationIfQty(
          "Weatherstripping- Door Appl. Door- Foam Tape", 
          "weatherstrippingDoor.applDoorFoamTape.location"
        ),
      },
    }),
    "weatherstrippingDoor.applDoorFoamTape.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Appl. Door - Foam Tape')
      }
    }),
    "weatherstrippingDoor.applDoorRigidGasket.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Weatherstripping- Door Appl. Door-Rigid Gasket", "weatherstrippingDoor.applDoorRigidGasket.nf"),
        location: hasLocationIfQty(
          "Weatherstripping- Door Appl. Door-Rigid Gasket", 
          "weatherstrippingDoor.applDoorRigidGasket.location"
        ),
      },
    }),
    "weatherstrippingDoor.applDoorRigidGasket.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Appl. Door - Rigid Gasket')
      }
    }),
    "weatherstrippingDoor.foamTapeVStrip.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Weatherstripping- Door Foam Tape/V Strip", "weatherstrippingDoor.foamTapeVStrip.nf"),
        location: hasLocationIfQty(
          "Weatherstripping- Door Foam Tape/V Strip", 
          "weatherstrippingDoor.foamTapeVStrip.location"
        ),
      },
    }),
    "weatherstrippingDoor.foamTapeVStrip.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Foam Tape/V Strip')
      }
    }),
    "weatherstrippingDoor.rigidGasket.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Weatherstripping- Door Rigid Gasket", "weatherstrippingDoor.rigidGasket.nf"),
        location: hasLocationIfQty("Weatherstripping- Door Rigid Gasket", "weatherstrippingDoor.rigidGasket.location"),
      },
    }),
    "weatherstrippingDoor.rigidGasket.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Rigid Gasket')
      }
    }),
    "weatherstrippingDoor.sliderPileSlidingGlass.qty": () => ({
      validate: {
        qtyOrNf: hasQtyOrNf("Weatherstripping- Door Slider Pile- (Sliding Glass)", "weatherstrippingDoor.sliderPileSlidingGlass.nf"),
        location: hasLocationIfQty(
          "Weatherstripping- Door Slider Pile- (Sliding Glass)", 
          "weatherstrippingDoor.sliderPileSlidingGlass.location"
        ),
      },
    }),
    "weatherstrippingDoor.sliderPileSlidingGlass.nf": () => ({
      validate: {
        nfEqualsZeroZero: nfEqualsZeroZero('Slider Pile - (Sliding Glass)')
      }
    }),
    "casingCaulkingDoor.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("casingCaulkingDoor.qty")) || VALIDATION_MESSAGES['casingCaulkingDoor.unit_validate'],
    }),
    "casingCaulkingWindow.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("casingCaulkingWindow.qty")) || VALIDATION_MESSAGES['casingCaulkingWindow.unit_validate'],
    }),
    "doorJambCaulking.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("doorJambCaulking.qty")) || VALIDATION_MESSAGES['doorJambCaulking.unit_validate'],
    }),
    "doorSC:24-36.qty": () => ({
      validate: (qty) => {
        const is24 = getValues("doorSC:24-36.is24");
        const is28 = getValues("doorSC:24-36.is28");
        const is30 = getValues("doorSC:24-36.is30");
        const is32 = getValues("doorSC:24-36.is32");
        const is36 = getValues("doorSC:24-36.is36");

        return (!!qty === (is24 || is28 || is30 || is32 || is36)) || VALIDATION_MESSAGES['doorSC:24-36.qty'];
      },
    }),
    "doorSC:34-42.qty": () => ({
      validate: (qty) => {
        const is34 = getValues("doorSC:34-42.is34");
        const is42 = getValues("doorSC:34-42.is42");

        return (!!qty === (is34 || is42)) || VALIDATION_MESSAGES['doorSC:34-42.qty'];
      },
    }),
    "doorStopCaulking.qty": () => ({
      validate: (qty) => (!!qty === !!getValues("doorStopCaulking.unit")) || VALIDATION_MESSAGES['doorStopCaulking.qty']
    }),
    "doorStopCaulking.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("doorStopCaulking.qty")) || VALIDATION_MESSAGES['doorStopCaulking.unit']
    }),
    "glassRepl:DS-SS.qty": () => ({
      validate: (qty) => {
        const isDS = getValues("glassRepl:DS-SS.isDS");
        const isSS = getValues("glassRepl:DS-SS.isSS");

        return (!!qty === (isDS || isSS)) || VALIDATION_MESSAGES['glassRepl:DS-SS.qty'];
      },
    }),
    "glassRepl:DS-SS.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("glassRepl:DS-SS.qty")) || VALIDATION_MESSAGES['glassRepl:DS-SS.unit']
    }),
    "glassReplLouvered.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("glassReplLouvered.qty")) || VALIDATION_MESSAGES['glassReplLouvered.unit'],
    }),
    "glassReplPolycarbonate.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("glassReplPolycarbonate.qty")) || VALIDATION_MESSAGES['glassReplPolycarbonate.unit'],
    }),
    "glassReplSpecialty.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("glassReplSpecialty.qty")) || VALIDATION_MESSAGES['glassReplSpecialty.unit'],
    }),
    "glassReplTempered.unit": () => ({
      validate: (unit) => (!!unit === !!getValues("glassReplTempered.qty")) || VALIDATION_MESSAGES['glassReplTempered.unit'],
    }),
    "squareFootage": () => ({
      validate: {
        required: (squareFootage) => {
          return (!!squareFootage) || VALIDATION_MESSAGES["squareFootage_required"]
        },
        number: (squareFootage) => {
          return (!isNaN(squareFootage)) || VALIDATION_MESSAGES["squareFootage_numbers"]
        },
      }
    }),
    "psi": () => ({
      validate: (value) => {
        return (!isNaN(value) && !!value) || VALIDATION_MESSAGES["waterPressure_numbers"]
      }
    }),
    "leftNoticeOfCondition.isChecked": () => ({
      validate: (isChecked) => {
        const isFurnace = getValues("leftNoticeOfCondition.isFurnace");
        const isWaterHeater = getValues("leftNoticeOfCondition.isWaterHeater");
        const isOther = getValues("leftNoticeOfCondition.isOther");
        return (!!isChecked && (isFurnace || isWaterHeater || isOther)) || !isChecked || VALIDATION_MESSAGES['leftNoticeOfCondition_isChecked']
      },
    }),
    "leftNoticeOfCondition.isFurnace": () => ({
      validate: (isFurnace) => {
        const isChecked = getValues("leftNoticeOfCondition.isChecked");
        const isWaterHeater = getValues("leftNoticeOfCondition.isWaterHeater");
        const isOther = getValues("leftNoticeOfCondition.isOther");
        if (isFurnace || isWaterHeater || isOther)
          return (!!isChecked) || VALIDATION_MESSAGES['leftNoticeOfCondition_toBeChecked']
      },
    }),
    "leftNoticeOfCondition.isOther": () => ({
      validate: (isOther) => {
        const isChecked = getValues("leftNoticeOfCondition.isChecked");
        const otherText = getValues("leftNoticeOfConditionOtherText");
        if ((!!isChecked && !!otherText)) 
          return (isOther) || VALIDATION_MESSAGES['leftNoticeOfCondition_isOther']
      },
    }),
    "leftNoticeOfConditionOtherText": () => ({
      validate: (value) => {
        const isChecked = getValues("leftNoticeOfCondition.isChecked");
        const isOther = getValues("leftNoticeOfCondition.isOther");
        if ((!!isChecked && isOther)) 
          return !!value || VALIDATION_MESSAGES['leftNoticeOfCondition_OtherText']
      },
    }),
    "programIdBadgeNumber": () => ({
      validate: {
        required: (value) => {
          return (!!value) || VALIDATION_MESSAGES["programIdBadgeNumber_required"]
        },
        // number: (value) => (!isNaN(value)) || VALIDATION_MESSAGES["programIdBadgeNumber_numbers"]
      }
    }),
    "customerSignature": () => ({
      validate: {
        required: (signature) => {
          return (!!signature) || VALIDATION_MESSAGES["customerSignature_required"]
        }
      }
    }),
    "customerName": () => ({
      validate: {
        required: (customerName) => {
          return (!!customerName) || VALIDATION_MESSAGES["customerName_required"]
        }
      }
    }),
    "installerName": () => ({
      validate: {
        required: (installerName) => {
          return (!!installerName) || VALIDATION_MESSAGES["installerName_required"]
        }
      }
    }),
    "installerSignature": () => ({
      validate: {
        required: (installerSignature) => {
          return (!!installerSignature) || VALIDATION_MESSAGES["installerSignature_required"]
        }
      }
    }),
  };
};

export default useValidation;