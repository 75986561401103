import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: props => ({
    display: 'flex',
    flexDirection: props.flexDirection ? props.flexDirection : 'row',
    flexWrap: props.flexWrap ? props.flexWrap : 'no-wrap',
    justifyContent: props.justifyContent ? props.justifyContent : 'stretch',
    alignItems: props.alignItems ? props.alignItems : 'unset',
    flex: props.flex ? props.flex : '0 1 auto',
    width: props.width ? props.width : '100%',
    height: props.height ? props.height : 'unset',
    padding: props.padding ? props.padding : 'unset',
    maxHeight: props.maxHeight ? props.maxHeight : 'unset'
  })
}))

function FlexContainer({ children, ...props }) {

  const styles = useStyles(props)

  return <div className={styles.root}>{children}</div>

}

export default FlexContainer;