import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

import useStyles from './styles';
import useGlobalLoading from 'hooks/useGlobalLoading';

function Loading({ isLoading, handleClose }) {
  const styles = useStyles();

  return (
    <Backdrop open={isLoading} onClick={handleClose} className={styles.backdrop}>
      <CircularProgress />
    </Backdrop>
  )
}

function LoadingSpinner() {
  const { isLoading } = useGlobalLoading();
  return (
    <Loading isLoading={isLoading}/>
  )
}

export default LoadingSpinner;