import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import StyledCard from '../StyledCard'

function CustomerCard({ customer }) {

  if (!customer) return null;
  return (
    <StyledCard>
      <Box mb={2}>
        <h3>Customer Information</h3>
        <Typography variant="body2">{ `${customer.firstName} ${customer.middleName ? customer.middleName : ""} ${customer.lastName}` }</Typography>
        <Typography variant="body2">{ `${customer.streetNumber} ${customer.streetName}`} {customer.unit ? `UNIT ${customer.unit}` : ''}</Typography>
        <Typography variant="body2">{ `${customer.city}, ${customer.zip}` }</Typography>
        <Typography variant="body2">CLIMATE ZONE: {customer.climateZone}</Typography>
        <Typography variant="body2">OCCUPANCY STATUS: {customer.occupancyStatus}</Typography>
      </Box>
      <Box mb={1}>
        <h5>Enrollment #</h5>
        <Typography>{ customer.enrollment.enrollmentNumber }</Typography>
      </Box>
      <Box mb={1}>
        {
          customer.primaryPhone && 
          <>
            <h5>Primary Phone</h5>
            <Typography>{ customer.primaryPhone }</Typography>
          </>
        }
        {
          customer.secondaryPhone && 
          <>
            <h5>Secondary Phone</h5>
            <Typography>{ customer.secondaryPhone }</Typography>
          </>
        }
      </Box>
      {
        customer.application?.language && 
        <Box mb={1}>
          <h5>Language</h5>
          <Typography>{ customer.application.language }</Typography>
        </Box>
      }
      <Box mb={1}>
        {
          customer.application?.electricProvider && 
          <>
            <h5>Electric Provider</h5>
            <Typography variant="body2">{ customer.application.electricProvider }</Typography>
          </>
        }
        {
          customer.application?.waterProvider && 
          <>
            <h5>Water Provider</h5>
            <Typography variant="body2">{ customer.application.waterProvider }</Typography>
          </>
        }
      </Box>
    </StyledCard>
  );
}

export default CustomerCard;