const VALIDATION_MESSAGES = {
  "newLocation_required": "New appliance location is required.",
  "newSerialNumber_required": "New appliance serial number is required.",
  "newModelNumber_required": "New appliance model number is required.",
  "newBrand_required": "New appliance brand is required.",
  "applicantSignature_required": "Applicant signature is required.",
  "applicantPrintedName_required": "Applicant printed name is required.",
  "applicantDate_required": "Applicant date is required.",
  "installerSignature_required": "Installer signature is required.",
  "installerPrintedName_required": "Installer printed name is required.",
  "insatllerDate_required": "Installer date is required.", 
  "psiBetween3080_value_required": "PSI value is required.",
  "12YearsOrOlder_onlyOneChecked": "Please check (only) one option for 12 years or older.",
  "psiBetween3080_onlyOneChecked": "Please check (only) one option for water pressure between 30-80 PSI.",
  "washerGasDryerPresent_onlyOneChecked": "Please check (only) one option for 'washer and gas dryer present'.",
  "energyESA_onlyOneChecked": "Please check (only) one option for 'utility providing ESA program'.",
  "waterProvidedByOccupant_onlyOneChecked": "Please check (only) one option for 'water provided by water heater under occupant's control'.",
  "4OrMoreHouseholdMembers_onlyOneChecked": "Please check (only) one option for 'household consists of 4 or more members'.",
  "customerOwnsWasher_onlyOneChecked": "Please check (only) one option for 'customer owns existing washer'.",
  "suitableLocation_onlyOneChecked": "Please check (only) one option for 'suitable location'.",
  "exposedToWeather_onlyOneChecked": "Please check (only) one option for 'is exposed to the weather'.",
  "isStackedUnit_onlyOneChecked": "Please check (only) one option for 'is a stacked unit'.",
  "isInCommonArea_onlyOneChecked": "Please check (only) one option for 'is in a common area'.",
  "suitableDrainSystem_onlyOneChecked": "Please check (only) one option for 'suitable water supply and/or drain system is available'.",
  "groundedReceptacleInReach_onlyOneChecked": "Please check (only) one option for 'properly grounded receptacle within 5ft'.",
  "isOutletGrounded_onlyOneChecked": "Please check (only) one option for 'grounded outlet'.",
  "existingHoseBib_onlyOneChecked": "Please check (only) one option for 'attach photo of existing hose bib'.",
  "clearPhoto_onlyOneChecked": "Please check (only) one option for 'clear photo taken of model and serial & serial number'.",
  "existingBrandOther_required": "Please fill out 'other' field for existing washer brand"
} 

const useValidation = (control) => {
  const { getValues } = control;

  return {
    "existingBrandOther": () => ({
      validate: {
        required: (value) => {
          const existingBrand =getValues("existingBrand")
          console.log(existingBrand === "Other")
          console.log(!!value)
          return ((existingBrand === "Other" && !!value) || (existingBrand !== "Other") || VALIDATION_MESSAGES["existingBrandOther_required"])
        }
      }
    }),
    "newLocation": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["newLocation_required"])
        }
      }
    }),
    "psiBetween3080_value": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["psiBetween3080_value_required"])
        }
      }
    }),
    "clearPhoto_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("clearPhoto_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["clearPhoto_onlyOneChecked"])
        }
      }
    }),
    "existingHoseBib_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("existingHoseBib_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["existingHoseBib_onlyOneChecked"])
        }
      }
    }),
    "isOutletGrounded_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("isOutletGrounded_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["isOutletGrounded_onlyOneChecked"])
        }
      }
    }),
    "groundedReceptacleInReach_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("groundedReceptacleInReach_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["groundedReceptacleInReach_onlyOneChecked"])
        }
      }
    }),
    "suitableDrainSystem_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("suitableDrainSystem_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["suitableDrainSystem_onlyOneChecked"])
        }
      }
    }),
    "isInCommonArea_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("isInCommonArea_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["isInCommonArea_onlyOneChecked"])
        }
      }
    }),
    "isStackedUnit_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("isStackedUnit_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["isStackedUnit_onlyOneChecked"])
        }
      }
    }),
    "exposedToWeather_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("exposedToWeather_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["exposedToWeather_onlyOneChecked"])
        }
      }
    }),
    "suitableLocation_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("suitableLocation_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["suitableLocation_onlyOneChecked"])
        }
      }
    }),
    "customerOwnsWasher_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("customerOwnsWasher_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["customerOwnsWasher_onlyOneChecked"])
        }
      }
    }),
    "4OrMoreHouseholdMembers_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("4OrMoreHouseholdMembers_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["4OrMoreHouseholdMembers_onlyOneChecked"])
        }
      }
    }),
    "waterProvidedByOccupant_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("waterProvidedByOccupant_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["waterProvidedByOccupant_onlyOneChecked"])
        }
      }
    }),
    "energyESA_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("energyESA_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["energyESA_onlyOneChecked"])
        }
      }
    }),
    "washerGasDryerPresent_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("washerGasDryerPresent_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["washerGasDryerPresent_onlyOneChecked"])
        }
      }
    }),
    "12YearsOrOlder_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("12YearsOrOlder_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["12YearsOrOlder_onlyOneChecked"])
        }
      }
    }),
    "psiBetween3080_yes": () => ({
      validate: {
        required: (value) => {
          const checkbox_no = getValues("psiBetween3080_no");
          return (value !== checkbox_no || VALIDATION_MESSAGES["psiBetween3080_onlyOneChecked"])
        }
      }
    }),
    "newSerialNumber": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["newSerialNumber_required"])
        }
      }
    }),
    "newModelNumber": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["newModelNumber_required"])
        }
      }
    }),
    "newBrand": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["newBrand_required"])
        }
      }
    }),
    "applicantSignature": () => ({
      validate: {
        required: (signature) => {
          return (!!signature || VALIDATION_MESSAGES["applicantSignature_required"])
        }
      }
    }),
    "applicantPrintedName": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["applicantPrintedName_required"])
        }
      }
    }),
    "applicantDate": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["applicantDate_required"])
        }
      }
    }),
    "installerPrintedName": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["installerPrintedName_required"])
        }
      }
    }),
    "installerSignature": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["installerSignature_required"])
        }
      }
    }),
    "installerDate": () => ({
      validate: {
        required: (value) => {
          return (!!value || VALIDATION_MESSAGES["installerDate_required"])
        }
      }
    }),
    "additionalInformation": () => ({
      validate: {
        required: (value) => {
          const furnace1 = getValues('furnace1.conditionCodes.8');
          const furnace2 = getValues('furnace2.conditionCodes.8');
          const waterHeater = getValues('waterHeater.conditionCodes.8');
          const other = getValues('other.conditionCodes.8');
          return (!(furnace1 || furnace2 || waterHeater || other) || !!value) || VALIDATION_MESSAGES["additionalInformation_required"]
        }
      }
    }),
  };
};

export default useValidation;
