import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PageLayout from 'components/PageLayout/PageLayout';
import StyledCard from 'components/StyledCard';
import useCustomer from 'hooks/useCustomer';
import FlexContainer from 'components/FlexContainer';
import InlineFormElement from 'components/InlineFormElement';
import BackButton from 'components/BackButton';
import useAPI from 'hooks/useAPI';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

function SubmitAppointment() {
  const { enrollmentNumber, appointmentId } = useParams()
  const { customer } = useCustomer(enrollmentNumber);
  const [users, setUsers] = useState([])
  const api = useAPI();
  const { handleSubmit, register, reset } = useForm();
  const history = useHistory();

  useEffect(() => {
    api
    .get('/users/intercompany')
    .then(res => {
      setUsers(res.data.users.filter(user => user.role.includes('tech.')))
      // After getting users list, we update the form
      if (appointmentId) {
        api
          .get(`/appointments/${appointmentId}`)
          .then(res => {
            reset({
              date: dayjs(res.data.appointment.start).format('YYYY-MM-DD'),
              start: dayjs(res.data.appointment.start).format('hh:mm:ss'),
              end: dayjs(res.data.appointment.end).format('hh:mm:ss'),
              technicianId: res.data.appointment.technician._id
            })
          })
          .catch(err => console.log(err))
      }
    })
    .catch(err => console.log(err))
    // eslint-disable-next-line
  }, [])
  
  const submitAppointment = data => {
    data.customer = customer._id
    if (!data.date || !data.start || !data.end || !data.technicianId) {
      toast.error('Please fill in all the values.');
      return;
    }
    // TODO: Should validate the input
    const startDate = dayjs(`${data.date} ${data.start}`);
    const endDate = dayjs(`${data.date} ${data.end}`);
    if (startDate > endDate) {
      toast.error('Invalid Time Input, start time should be earlier than end time.');
      return;
    }

    if (appointmentId) updateAppointment(data); else createAppointment(data);
  }

  const createAppointment = data => {
    const startDate = dayjs(`${data.date} ${data.start}`);
    const endDate = dayjs(`${data.date} ${data.end}`);
    api
      .post(`/appointments`, { ...data, start: startDate.toISOString(), end: endDate.toISOString(), status: { _id: 'scheduled' }, customer: customer._id })
      .then(() => {
        toast.success('Successfully created an appointment.')
        history.push(`/customers/${enrollmentNumber}`)
      })
      .catch((error) => toast.error(error.toString() || 'An unexpected error occurs, please contact administrator'))
      ;
  }

  const updateAppointment = data => {
    const startDate = dayjs(`${data.date} ${data.start}`);
    const endDate = dayjs(`${data.date} ${data.end}`);
    api
      .put(`/appointments/${appointmentId}`, { ...data, start: startDate.toISOString(), end: endDate.toISOString(), status: 'scheduled' })
      .then(() => {
        history.push(`/customers/${enrollmentNumber}`)
        toast.success('Successfully updated an appointment.')
      })
      .catch((error) => toast.error(error.toString() || 'An unexpected error occurs, please contact administrator'))
      ;

    const statusData = { status: 'scheduled' };

    api
      .put(`/appointments/${appointmentId}/update-status`, statusData)
      .then(res => {
        console.log(res)
        toast.success('Appointment has been marked as "scheduled".')
      })
      .catch(err => {
        console.log(err.message)
        toast.warning(err.message)
      })
  }

  if (!customer) return null;

  return (
    <PageLayout title={customer?.firstName}>
      <Container maxWidth="md">
        <FlexContainer flexDirection='column' width='40%'>
          <Box display="flex" justifyContent="flex-end" my={1}>
            <BackButton url={`/customers/${enrollmentNumber}`} />
          </Box>
          <StyledCard>
            <Typography variant="h6">Customer Information</Typography>
            <Typography variant="body2">{ `${customer?.firstName} ${customer.middleName ? customer.middleName : ""} ${customer?.lastName}` }</Typography>
            <Typography variant="body2">{ `${customer?.streetNumber} ${customer?.streetName}` } {customer?.unit ? `UNIT ${customer?.unit}` : ''}</Typography>
            <Typography variant="body2">{ `${customer?.city}, ${customer?.state || ''}, ${customer?.zip}` }</Typography>
            <Typography variant="body2">Enrollment #:{ `${customer?.enrollment?.enrollmentNumber}` }</Typography>
            <Typography variant="body2">CLIMATE ZONE: {customer?.climateZone}</Typography>
            <Typography variant="body2">OCCUPANCY STATUS: {customer?.occupancyStatus}</Typography>
          </StyledCard>
          <form onSubmit={handleSubmit(submitAppointment)}>
            <StyledCard>
              <Typography variant="h6">Job Information</Typography>
                <InlineFormElement label='Date'>
                  <input ref={register} type='date' name='date' className='w-full' />
                </InlineFormElement>
                <InlineFormElement label='Start Time'>
                  <input ref={register} type='time' name='start' className='w-full' />
                </InlineFormElement>
                <InlineFormElement label='End Time'>
                  <input ref={register} type='time' name='end' className='w-full' />
                </InlineFormElement>
                <InlineFormElement label='Technician'>
                  <select required ref={register} name='technicianId' className='w-full'>
                    {users.map(user => <option value={user._id} key={user._id}>{`${user.firstName} ${user.lastName}`}</option>)}
                  </select>
                </InlineFormElement>
            </StyledCard>
            <Button 
              variant="contained"
              className="w-full"
              color="primary"
              type='submit'
              onClick={handleSubmit}
            >
              Submit Appointment
            </Button>          
          </form>
        </FlexContainer>
      </Container>
    </PageLayout>
  )
}

export default SubmitAppointment;