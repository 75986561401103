import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAPI from 'hooks/useAPI';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { getPersonName, isSuccessfulResponse } from 'utils/helper';
import StyledCard from 'components/StyledCard';
import PageLayout from 'components/PageLayout/PageLayout';

function ManageAccount({ user }) { 
  const { accountId } = useParams();
  const [account, setAccount] = useState();
  const [users, setUsers] = useState();
  const [accessList, setAccessList] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [removedUsers, setRemovedUsers] = useState([]);
  const api = useAPI();

  const fetchRecords = async () => {
    let sharedUsers = [];
    let localAccount;
    try {
      const accountResponse = await api.get(`/utility-accounts/${accountId}`);
      if (isSuccessfulResponse(accountResponse)) {
        setAccount(accountResponse.data.account)
        localAccount = accountResponse.data.account;
        sharedUsers = accountResponse.data.account.sharedUsers;
        setAccessList(sharedUsers)
      }
    } catch(error) {
      console.log("utility-account error", error)
    }

    try {
      const userResponse = await api.get('/users');
      sharedUsers = new Set(sharedUsers.map(user => user._id))
      if (isSuccessfulResponse(userResponse)) {
        setUsers(userResponse.data.users.filter(user => (!sharedUsers.has(user._id) && user._id !== localAccount.owner._id)))
      }
    } catch(error) {
      console.log("user error", error)
    }

  }

  useEffect(() => {
    fetchRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeSharedUsers = () => {
    api
      .delete(`utility-accounts/${accountId}/shared`, { 
        data: {
          users: removedUsers.map(user => user._id) 
        }
      })
      .then(res => {
        setRemovedUsers([])
        fetchRecords();
      })
      .catch(err => console.log(err))
  }

  const addSharedUsers = () => {
    api
      .post(`utility-accounts/${accountId}/shared`, { users: addedUsers })
      .then(res => {
        setAddedUsers([])
        fetchRecords();
      })
      .catch(err => console.log(err))
  }

  const columns = [
    { 
      title: 'User', editable: 'never',
      render: (rowData) => {
        return (
          <div>
            { getPersonName(rowData) }
          </div>
        );
      }
    }
  ];

  if (!account) return null;

  return (
    <PageLayout title={getPersonName(user)}>
      <Box>
        <Box width="20%">
          <StyledCard>
            <Typography>{ account.type }</Typography>
            <Box my={2}>
              <TextField name="username" variant="outlined" size="small" value={account.username} disabled className='w-full' />
            </Box>
          </StyledCard>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h3>Remove Shared Users</h3>
            <MaterialTable 
              columns={columns}
              data={accessList}
              options={{
                selection: true,
                toolbar: false
              }}
              onSelectionChange={(rows) => setRemovedUsers(rows)}
            />
            <Box display="flex" justifyContent="flex-end" my={1}>
              <Button variant="contained" color="primary" onClick={removeSharedUsers}>Remove</Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <h3>Add Shared Users</h3>
            <MaterialTable 
              columns={columns}
              data={users}
              options={{
                selection: true,
                toolbar: false
              }}
              onSelectionChange={(rows) => setAddedUsers(rows)}
            />
            <Box display="flex" justifyContent="flex-end" my={1}>
              <Button variant="contained" color="primary" onClick={addSharedUsers}>Add</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  )
}

export default ManageAccount;