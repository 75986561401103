import React, { useState, useEffect } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PageLayout from 'components/PageLayout/PageLayout';
import useAPI from 'hooks/useAPI';
import { format } from 'date-fns';

function AuditingAppointment() { 
  const { appointmentId } = useParams()
  
  const { url } = useRouteMatch();
  const [submissions, setSubmissions] = useState([]);
  const [images, setImages] = useState([]);
  const api = useAPI();
  
  const getSubmissions = () => {
    api
      .get(`/appointments/${appointmentId}/submissions`)
      .then(res => {
        setSubmissions(res.data.submissions)
        console.log(submissions)
      })
      .catch(err => console.log(err))
  }

  const getImages = () => {
    api
      .get(`/appointments/${appointmentId}/get-images`)
      .then(res => {
        setImages(res.data.images)
        console.log(images)
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    getSubmissions();
    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (submissions) {
      submissions.forEach(submission => {
        api
          .get(`/forms/${appointmentId}/revision/${submission.currentRevision}`)
          .then(res => {
            submission.currentRevision = res.data.revision
            console.log(submission)
          })
          .catch(err => console.log(err))
      })
    }
  }, [submissions])

  return (
    <PageLayout title={`Auditing Appointment`}>
      <Box display="flex" flexDirection="column" p={2}>
        <Typography variant="h5" component="h3">submissions</Typography>
        {submissions.map(submission => (
          <Link key={submission._id} to={`${url}/submission/${submission.type}/${submission.version}/${submission._id}`}>{`${submission.type} - ${format(new Date(submission?.createdAt), 'MM-dd-yyyy - hh:mm aaaa')}`}</Link>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" p={2}>
        <Typography variant="h5" component="h3">Pictures</Typography>

      </Box>
    </PageLayout>
  )
}

export default AuditingAppointment