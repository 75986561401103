import { useEffect } from 'react';
import useAPI from 'hooks/useAPI';
import useGlobalLoading from 'hooks/useGlobalLoading';
import { useHistory } from 'react-router-dom';

/**
 * 
 * @param {Object} props 'setRows' is the state setting function used to set the rows for the customers with the api's response
 * @returns 'viewCustomer', a function that redirects the user to the customer's information page,
 *  and 'search', the function that updates the criteria and requests a new batch of customers fitting that criteria.
 */

function useCustomers({ setRows }) {
  const api = useAPI();
  const { setIsLoading } = useGlobalLoading();
  const history = useHistory();
  const viewCustomer = enrollmentNumber => {
    history.push(`/customers/${enrollmentNumber}`)
  }
  const search = data => {
    const utilityAccountId = localStorage.getItem('accountId');
    if(!utilityAccountId) {
      alert('please add and select a utility account before searching via enrollment number')
      return
    }
    if (data.enrollmentNumber) {
      api
        .get(`/heat/${utilityAccountId}/enrollment/${data.enrollmentNumber}`)
        .then(res => setRows([res.data.customer]))
        .catch(err => console.log(err))
    }
    else {
      const criteria = Object
        .entries(data)
        .reduce((accumulator, kv) => {
          const [key, value] = kv;
          if (value && value.length) {
            accumulator[key] = value;
          }

          return accumulator;
        }, {});
      sessionStorage.setItem('criteria', JSON.stringify(criteria));

      api
        .get('/customers', {
          params: criteria
        })
        .then(res => {
          setRows(res.data.customers)
        })
        .catch(err => console.log(err.message))
    }
  }

  useEffect(() => {
    setIsLoading(true)
    api
      .get('/customers')
      .then(res => {
        setRows(res.data.customers)
      })
      .catch(err => console.log(err))
      .finally(setIsLoading(false))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { viewCustomer, search };
}

export default useCustomers;