const VALIDATION_MESSAGES = {
  "customer.name_required": "Customer Name is required.",
  "customer.signature_required": "Customer Signature is required.",
  "additionalInformation_required": "When Any Condition Code #8 is check marked, comment section cannot be left blank."
} 

const useValidation = (control) => {
  const { getValues } = control;

  return {
    "customer.signature": () => ({
      validate: {
        required: (signature) => {
          const refusedSign = getValues('customerRefusedToSign.check');
          return (!!signature || !!refusedSign) || VALIDATION_MESSAGES["customer.signature_required"]
        }
      }
    }),
    "customer.name": () => ({
      validate: {
        required: (customerName) => {
          const signature = getValues('customer.signature');
          const refusedSign = getValues('customerRefusedToSign.check');
          return ((!!signature || !!refusedSign) && !!customerName) || VALIDATION_MESSAGES["customer.name_required"]
        }
      }
    }),
    "additionalInformation": () => ({
      validate: {
        required: (value) => {
          const furnace1 = getValues('furnace1.conditionCodes.8');
          const furnace2 = getValues('furnace2.conditionCodes.8');
          const waterHeater = getValues('waterHeater.conditionCodes.8');
          const other = getValues('other.conditionCodes.8');
          return (!(furnace1 || furnace2 || waterHeater || other) || !!value) || VALIDATION_MESSAGES["additionalInformation_required"]
        }
      }
    }),
  };
};

export default useValidation;
