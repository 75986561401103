import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import PageLayout from 'components/PageLayout/PageLayout';
import { useHistory } from 'react-router-dom'
import BackButton from 'components/BackButton';


function Regions() {
  const history = useHistory()

  return (
    <PageLayout title={'Invoicing'}>
      <Box width={375} marginLeft="auto" marginRight="auto">
        <Box my={1} fullWidth justifyContent="center">
          <Typography align="center">Select the region to invoice for</Typography>
        </Box>
        <Box my={1} fullWidth>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={() => history.push('/invoicing/reliable')}
          >
            Reliable
          </Button>
        </Box>
        <Box my={1} fullWidth>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={() => history.push('/invoicing/assert')}
          >
            Assert
          </Button>
        </Box>
        <Box my={1} fullWidth>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={() => history.push('/invoicing/bakersfield')}
          >
            BakersField
          </Button>
        </Box>
        <Box my={1} fullWidth>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={() => history.push('/invoicing/lancaster')}
          >
            Lancaster
          </Button>
        </Box>
        <Box my={1} justifyContent="center">
          <BackButton url='/' buttonProps={{fullWidth: true}} />
        </Box>
      </Box>
    </PageLayout>
  )
}

export default Regions;