import React from 'react'; 
import FlexContainer from 'components/FlexContainer';
import useNotifications from './useNotifications';
import useAuth from 'hooks/useAuth'
import { Link, useLocation } from 'react-router-dom';

function Notifications() {
  const { notifications } = useNotifications();
  const { accessToken } = useAuth()
  const location = useLocation()

  if (accessToken) return (
    <FlexContainer>
      {notifications && notifications.filter(notif => notif.path !== location.pathname).map(notif => (
        <Link key={notif.message} to={notif.path}>{notif.message}</Link>
      ))}
    </FlexContainer>
  )
  else return <div></div>
}

export default Notifications;