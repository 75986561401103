import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography'

export const SuccessTypography = withStyles({
  root: {
    color: "#4A9251"
  }
})(Typography);

export const GrayTypography = withStyles({
  root: {
    color: "#959595"
  }
})(Typography);