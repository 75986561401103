import { useState } from 'react';
import AlertDialog from './AlertDialog';

const useAlertDialog = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [variant, setVariant] = useState('');
  const [description, setDescription] = useState('');
  const [onSubmit, setOnSubmit] = useState(null);
  const [onClose, setOnClose] = useState(null);
  const closeDialog = () => setOpen(false);

  const AlertDialogComponent = () => {
    return <AlertDialog 
      open={open}
      title={title}
      variant={variant}
      description={description}
      onSubmit={closeDialog}
      onClose={onClose}
    />
  }

  return {
    open,
    AlertDialogComponent,
    setOpen,
    setTitle,
    setVariant,
    setDescription,
    setOnSubmit,
    setOnClose,
  }
}

export default useAlertDialog;