import React, { forwardRef, useRef, useEffect } from "react";
import { useWatch } from "react-hook-form";
import checkmark from "./checkmark.png";

const Checkbox = forwardRef((props, ref) => {
  const {
    id,
    name,
    width,
    height,
    left,
    top,
    outline,
    control,
    onChange,
  } = props;
  const isChecked = useWatch({ control, name });
  const checkedRef = useRef(null);

  useEffect(() => {
    // for RHF version 7 and higher
    // onChange({ target: { name, value: isChecked } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <div
      onClick={() => control.setValue(name, !isChecked)}
      style={{
        position: "absolute",
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
        outline: outline || "none",
        backgroundImage: isChecked ? `url(${checkmark})` : "none",
        backgroundSize: "contain",
      }}
    >
      <input
        type="checkbox"
        // for RHF version 6 
        onChange={onChange}
        readOnly
        id={id}
        name={name}
        checked={isChecked}
        ref={(targetRef) => {
          ref(targetRef);
          checkedRef.current = targetRef;
        }}
        style={{ display: "none" }}
      />
    </div>
  );
});

export default Checkbox;
