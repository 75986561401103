import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useAPI from 'hooks/useAPI';
import useAuth from 'hooks/useAuth';
import { Box, Typography, TextField, Button } from "@material-ui/core";
import PageLayout from 'components/PageLayout';
import StyledCard from 'components/StyledCard';

function LogIn() {
  const history = useHistory();
  const api = useAPI();
  const { setAccessToken } = useAuth();
  const { register, handleSubmit, errors } = useForm();

  const login = data => {
    api
      .post('/authentication/login', data)
      .then(res => {
        const { accessToken } = res.data
        setAccessToken(accessToken)
        history.push('/home');
      })
      .catch(err => console.log(err));
  }

  return (
    <PageLayout title='Log In'>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Box width="60%" mx="auto" mt={10}>
          <form onSubmit={handleSubmit(login)}>
            <StyledCard px={15}>
              <Box display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='center'>
                  <Typography variant={'h4'}>Log in</Typography>
                </Box>
                <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                  <label for="username">Username</label>
                  <TextField required name="username" variant="outlined" size="small" inputRef={register} />
                  {
                    errors.username && (
                      <p>{errors.username.message}</p>
                    )
                  }
                </Box>
                <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                  <label for="username">Password</label>
                  <TextField required name="password" type="password" variant="outlined" size="small" inputRef={register} />
                  {errors.password && (
                    <p>Please enter a password.</p>
                  )}
                </Box>
                <Box justifyContent="flex-end" display="flex" my={1}>
                  <Button type="submit" onClick={handleSubmit} color="primary" variant="contained">Login</Button>
                </Box>
              </Box>
            </StyledCard>

          </form>
        </Box>
        <Box padding='30px 0px 0px 0px' justifyContent='center' alignContent='center'>
          <Button onClick={() => history.push('/therms')} color="primary" variant="contained">Therms Calculator</Button>
        </Box>
        <Box padding='30px 0px 0px 0px' justifyContent='center' alignContent='center'>
          <Button onClick={() => history.push('/prtherms')} color="primary" variant="contained">Rep Therms Calculator</Button>
        </Box>

      </Box>
    </PageLayout>
  )
}

export default LogIn;
