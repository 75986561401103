import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  box: {
    background: '#f9f9f9',
  }
})

function StyledCard({ children, ...props }) {

  const styles = useStyles()

  return (
    <Box my={2}>
      <Card {...props}>
        <Box p={2} className={styles.box}>{children}</Box>
      </Card>
    </Box>
    );
}

export default StyledCard;