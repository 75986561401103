import React from 'react';
import Box from '@material-ui/core/Box';

function InlineFormElement({ children, label }) {

  return (
    <Box display="flex" my={1} alignItems="center">
      <Box flex={1}>
        <label>{label}</label>
      </Box>
      <Box flex={1}>{children}</Box>
    </Box>
  );
}

export default InlineFormElement;