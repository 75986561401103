import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PageLayout from 'components/PageLayout/PageLayout';
import StyledCard from 'components/StyledCard';
import BackButton from 'components/BackButton';
import useAccounts from 'hooks/useAccounts';
import axiosWithAuth from 'utils/axiosWithAuth';
import useUser from 'hooks/useUser';
import ListAccounts from 'pages/ManageUser/ListAccounts';
import { UTILITY_ACCOUNT_OPTIONS } from 'utils/helper';
import useAlertDialog from 'components/AlertDialog';
import useGlobalLoading from 'hooks/useGlobalLoading';
import { useHistory } from 'react-router-dom';

function Accounts() {
  const history = useHistory();
  const { setIsLoading } = useGlobalLoading();
  const { user } = useUser();
  const { register, handleSubmit, reset } = useForm();
  const [accountType, setAccountType] = useState('');
  const defaultValues = {
    username: '',
    password: ''
  };
  const {
    AlertDialogComponent,
    setOpen,
    setTitle,
    setDescription,
    open
  } = useAlertDialog();
  const { ownedAccounts, sharedAccounts } = useAccounts(user._id, open);

  const addAccount = data => {
    setIsLoading(true)
    console.log(accountType)
    axiosWithAuth()
      .post(`/${accountType.value}/accounts`, data)
      .then(res => {
        localStorage.setItem('accountId', res.data.accountId);
        setOpen(true)
        setTitle('Account Successfully Added')
        setDescription(res.data.message)
        reset(defaultValues)
        setAccountType('');
        if (res.data.message === 'HEAT account needs further authentication. Check your email and enter the code.') {
          setIsLoading(false)
          history.push('/two-step-verification');
        };
      })
      .catch(err => {
        console.log(err)
        setOpen(true)
        setTitle('Error')
        setDescription(err.response.data.message)
      })
      .finally(() => setIsLoading(false));
  }
  
  return (
    <PageLayout title='Accounts'>
      <AlertDialogComponent />
      <Box width="45%" display="flex" mt={5}>
        <Box width="45%">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Add a Utility Account</Typography>
            <BackButton url='/' />
          </Box>
          <StyledCard>
            <form onSubmit={handleSubmit(addAccount)}>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Box my={2}>
                    <Select
                      isClearable
                      options={UTILITY_ACCOUNT_OPTIONS}
                      value={accountType}
                      onChange={(value) => setAccountType(value)}
                      placeholder="Type"
                      menuPortalTarget={document.body} 
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </Box>
                  <Box my={2}>
                    <TextField name="username" placeholder="username" variant="outlined" size="small" inputRef={register} className='w-full' />
                  </Box>
                  <Box my={2}>
                    <TextField type='password' name="password" placeholder="password" variant="outlined" size="small" inputRef={register} className='w-full' />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                  <Button 
                    variant="contained"
                    color="primary"
                    type="submit"
                    >
                    Submit
                  </Button>
                </Box>
              </Box>
            </form>
          </StyledCard>
        </Box>
      </Box>
      {
        ownedAccounts && ownedAccounts.length > 0 && (
          <Box mt={5}>
            <Typography>My Utility Accounts</Typography>
            <ListAccounts ownedAccounts={ownedAccounts} updateButtonLabel="Update" layout="row" />
          </Box>
        )
      }
      {
        sharedAccounts && sharedAccounts.length > 0 && (
          <Box mt={5}>
            <Typography>Shared Accounts</Typography>
            <ListAccounts ownedAccounts={sharedAccounts} isOwner={false} />
          </Box>
        )
      }
    </PageLayout>
  )
}

export default Accounts;