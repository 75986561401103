import React from 'react';
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import useUser from 'hooks/useUser'; 
import PageLayout from 'components/PageLayout';

function Home() {
  const { user } = useUser(); 
  return (
    <PageLayout title={'Home'}>
      <h1>Welcome, {user?.firstName}</h1>
      <Box p={5}>
          * NOTICE: Auditing update has been applied. Please create a new "AUDITOR" account in order to process any forms submitted during and after the week of 5/22. Keep your old accounts to process older enrollments. Contact Alexis, Robert, or Josue for any questions or for any bug reports regarding the update.
      </Box>
      <Box display="flex" justifyContent="space-around">
        <Card>
          <Box p={5}>
            <Link color="primary" to={`/customers`}>Customer Bin</Link>
          </Box>
        </Card>
        <Card>
          <Box p={5}>
            <Link color="primary" to="/accounts">My Accounts</Link>
          </Box>
        </Card>
        {(user?.role === 'admin') && (
          <Card>
            <Box p={5}>
              <Link color="primary" to="/therm-report">Run Therm Report</Link>
            </Box>
          </Card>
        )}
      </Box>
    </PageLayout>
  )
}

export default Home;