import React from 'react';
import DayJS from 'react-dayjs';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';

function CommentCard(props) { 
  const { comment } = props;

  console.log({ comment })
  return (
    <>
      <Divider/>
        <Box flexDirection={'column'} padding='10px'>
          <Box flexDirection={'row'}>
            <h6>{`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}</h6>
            <p>{`${comment.message}`}</p>
            <DayJS format='MM/DD/YYYY'>{comment.createdAt}</DayJS>
          </Box>
        </Box>
      <Divider/>
    </>
  )
}

export default CommentCard;