import React, { useEffect, useState } from 'react';
import useAPI from 'hooks/useAPI';
import { useForm } from 'react-hook-form';
import PageLayout from 'components/PageLayout';
import { Box, TextField, Button } from "@material-ui/core";
import { ROLE_OPTIONS } from 'utils/helper';
import StyledCard from 'components/StyledCard';
import BackButton from 'components/BackButton';
import Select from 'react-select';
import { toast } from 'react-toastify';
import useGlobalLoading from 'hooks/useGlobalLoading';
import useAlertDialog from 'components/AlertDialog';

function UserRegistration() {
  const { setIsLoading } = useGlobalLoading();
  const api = useAPI();
  // eslint-disable-next-line no-unused-vars
  const [roles, setRoles] = useState();
  const [role, setRole] = useState();
  const { register, handleSubmit, reset, } = useForm();

  const { 
    AlertDialogComponent,
    setOpen,
    setTitle,
    setDescription,
    open
  } = useAlertDialog();

  const defaultValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    username: '',
    role: ''
  }

  const createUser = data => {
    if (!role) {
      toast.warning('Please select a user role first.');
      return;
    }
    setIsLoading(true)
    api
      .post('/authentication/register', {...data, role: role.value})
      .then((res) => {
        setOpen(true);
        setTitle('User registered');
        setDescription(`Please write down this temporary password: ${res.data.user.password}`)
        toast.success(res?.data?.message || `A user registered successfully`);
        reset(defaultValues);
        setRole('')
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    api
      .get('/roles')
      .then(res => setRoles(res.data.roles))
      .catch(err => console.log(err))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeRole = (value) => {
    setRole(value);
  }

  return (
    <PageLayout title='User Registration'>
      <AlertDialogComponent/>
      <Box width="400px" mx="auto" mt={10}>
        <form onSubmit={handleSubmit(createUser)}>
          <StyledCard px={15}>
            <Box display='flex' flexDirection='column'>
              <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                <TextField required name='firstName' variant='outlined' placeholder='First name' size="small" inputRef={register} />
              </Box>
              <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                <TextField name='middleName' variant='outlined' placeholder='Middle name' size="small" inputRef={register} />
              </Box>
              <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                <TextField required name='lastName' variant='outlined' placeholder='Last name' size="small" inputRef={register} />
              </Box>
              <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                <TextField required placeholder='Username' name='username' variant='outlined' size="small" inputRef={register} />
              </Box>
              <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                <Select
                  required
                  value={role}
                  onChange={onChangeRole}
                  name='role'
                  placeholder='Role'
                  isClearable
                  options={ROLE_OPTIONS}
                  menuPortalTarget={document.body} 
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </Box>
              <Box justifyContent="flex-end" display="flex" my={1}>
                <Button type="submit" onClick={handleSubmit} color="primary" variant="contained">Submit</Button>
                <BackButton url='/' ml={1} />
              </Box>
            </Box>
          </StyledCard>

        </form>
      </Box>
    </PageLayout>
  )
}

export default UserRegistration;