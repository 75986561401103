import React, { useState, useEffect } from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useAPI from 'hooks/useAPI';
import StyledCard from 'components/StyledCard';
import BackButton from 'components/BackButton';
import { toast } from 'react-toastify';
import useGlobalLoading from 'hooks/useGlobalLoading';

function UpdateAccount() {
  const { setIsLoading } = useGlobalLoading();
  const { accountId } = useParams();
  const { register, handleSubmit, setValue } = useForm();
  const [accountType, setAccountType] = useState();
  const history = useHistory();
  const api = useAPI();

  const submit = data => {
    setIsLoading(true)
    api
      .put(`/${accountType}/${accountId}`, data)
      .then(res => {
        alert(res.message)
        history.push('/accounts')
      })
      .catch(err => {
        console.log(err)
        alert(err)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    api
      .get(`/utility-accounts/${accountId}`)
      .then(res => {
        setAccountType(res.data.account.type)
        setValue('username', res.data.account.username)
      })
      .catch(err => console.log(err))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDelete = () => {
    api
      .delete(`/utility-accounts/${accountId}`)
      .then(res => {
        history.push('/accounts');
        toast.success(res.data.message);
      })
      .catch(err => {
        toast.error(err.message);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <PageLayout title='Update Account'>
      <Box width="45%" display="flex" mt={5}>
        <Box width="45%">
          <Box display="flex" justifyContent="flex-end">
            <BackButton url='/accounts' />
          </Box>

          <StyledCard>
            <form onSubmit={handleSubmit(submit)}>
              <Box mb={2}>
                <Typography>{accountType}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" className="w-full" my={1}>
                <Box mr={2}>
                  <TextField name="username" placeholder="username" variant="outlined" size="small" inputRef={register} disabled className='w-full' />
                </Box>
                <Button 
                  variant="contained"
                  color="primary"
                  type="submit"
                  >
                  Update
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between" className="w-full" my={1}>
                <Box mr={2}>
                  <TextField name="password" placeholder="password" variant="outlined" size="small" inputRef={register} className='w-full' />
                </Box>
                <Button 
                  variant="contained"
                  color="secondary"
                  onClick={onDelete}
                  >
                  Delete
                </Button>
              </Box>
            </form>
          </StyledCard>
        </Box>
      </Box>
    </PageLayout>
  )
}

export default UpdateAccount;