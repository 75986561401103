import { useState } from 'react';
import useAPI from 'hooks/useAPI';

/**
 * 
 * @returns the functions and state required to handle pagination for the customerbin component
 */
function usePagination() {
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([]); 
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCustomers, setTotalCustomers] = useState(0)
  const api = useAPI();

  const handleChangePage = (event, newPage) => {
    const criteria = sessionStorage.getItem('criteria') 
      ? JSON.parse(sessionStorage.getItem('criteria'))
      : { assignedTo: 'pre-screened', rows: rowsPerPage }
    api
      .get('customers/scg', {
        params: { 
          ...criteria,
          rows: rowsPerPage,
          page: newPage + 1
        }
      })
      .then(res => {
        setRows(res.data.customers);
        setPage(newPage)
      })
      .catch(err => console.log(err.message))
  };

  const handleChangeRowsPerPage = event => {
    const criteria = sessionStorage.getItem('criteria') 
      ? JSON.parse(sessionStorage.getItem('criteria'))
      : { assignedTo: 'pre-screened', rows: rowsPerPage }
    api
      .get('/customers', {
        params: { 
          ...criteria,
          rows: event.target.value,
          page: 1
        }
      })
      .then(res => {
        setRowsPerPage(event.target.value);
        setRows(res.data.customers);
        setTotalCustomers(res.data.customers.length)
        setPage(0);
      })
      .catch(err => console.log(err.message));
  };

  return { 
    handleChangePage, 
    handleChangeRowsPerPage,
    page,
    rows,
    setRows,
    rowsPerPage,
    totalCustomers
  };
}

export default usePagination;