import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import PageLayout from 'components/PageLayout/PageLayout';
import FlexContainer from 'components/FlexContainer';
import { SecondaryButton } from 'components/CustomButton';
import useAPI from 'hooks/useAPI';
import { getPersonName } from 'utils/helper';
const DEFAULT_PAGE_SIZE = 10;


function RegionInvoicing() {
  const { region } = useParams()
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);


  const api = useAPI();
  const columns = [
    { 
      title: 'Enrollment#', editable: 'never',
      render: (rowData) => rowData._id
    },
    { 
      title: 'HEAT Invoice#', editable: 'never',
      render: (rowData) => (
        <div>
          <div>
            Technician: <span>{ rowData.technician ? getPersonName(rowData.technician) : '' }</span>
          </div>
        </div>        
      )
    }
  ];

  const fetchInvoiceList = (query) => {
    const url = `/appointments/list?page=${query.page}&limit=${query.pageSize}`;
    return api.get(url)
      .then(res => res.data)
  }

  const onSelectionChange = (rows) => {
    console.log("on selection change", rows)
  }

  return (
    <PageLayout title={`Invoicing - ${region}`}>
      <Box mb={2} mt={1}>
        <FlexContainer justifyContent='flex-end'>
          <Button variant='contained' color='primary'>Complete</Button>
          <Box ml={3}>
            <SecondaryButton variant='contained' color='#CB8262'>Send Back To Auditing</SecondaryButton>
          </Box>
        </FlexContainer>
      </Box>
      <Box p={2}>
        <MaterialTable
          title="Invoicing"
          columns={columns}
          data={query => {
            return fetchInvoiceList(query)
          }}
          onChangeRowsPerPage={(pageSize) => {
            setPageSize(pageSize);
          }}
          onSelectionChange={onSelectionChange}
          options={{
            pageSize,
            emptyRowsWhenPaging: false,
            toolbar: false,
            selection: true
          }}
          />
      </Box>
    </PageLayout>
  )
}

export default RegionInvoicing;