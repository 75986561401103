import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useHistory, useRouteMatch } from 'react-router-dom';
import StyledCard from 'components/StyledCard';
import { getPersonName } from 'utils/helper'

function ListAccounts({ ownedAccounts, updateButtonLabel = 'Manage Access', layout="column", isOwner = true }) {
  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <Box display="flex" flexWrap="wrap" mx={0}>
      {ownedAccounts && ownedAccounts.map(account => (
        <Box width="45%" marginRight="5%" display="flex" key={account._id}>
          <Box width="45%" marginRight="5%">
            <StyledCard>
              <Box display="flex" justifyContent="space-between" flexDirection={layout}>
                <Box>
                  <Typography>{ account.type }</Typography>
                  <Box my={2}>
                    <TextField name="username" variant="outlined" size="small" value={account.username} disabled className='w-full' />
                  </Box>
                  <Box my={2}>
                    <TextField name="password" variant="outlined" size="small" disabled className='w-full' />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                  {
                    isOwner && 
                    <Button 
                      variant="contained"
                      color="primary"
                      onClick={() => history.push(`${url}/${account._id}`)}
                    >
                      { updateButtonLabel }
                    </Button>
                  }
                </Box>
              </Box>
            </StyledCard>
          </Box>
          <Box width="45%" marginRight="5%">
            <StyledCard>
              <Typography>Owner: { getPersonName(account.owner) }</Typography>
              <Box my={2}>
                <Typography>Shared Users</Typography>
                <ul className="shared-users-list">
                  {
                    account.sharedUsers && 
                    account.sharedUsers.map(user => <li>{ getPersonName(user) }</li>)
                  }
                </ul>
              </Box>
            </StyledCard>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default ListAccounts;