import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Offline } from '@sentry/integrations'
import App from './App';
import "./style.css";

import LoadingProvider from 'providers/LoadingProvider';
import NavProvider from 'providers/NavProvider';
import AccessTokenProvider from 'providers/AccessTokenProvider';
import UserProvider from 'providers/UserProvider'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Offline()],
  tracesSampleRate: 1.0
});


render(
  <BrowserRouter>
    <AccessTokenProvider>
      <UserProvider>
        <NavProvider>
          <LoadingProvider>
            <App/>
          </LoadingProvider>
        </NavProvider>
      </UserProvider>
    </AccessTokenProvider>
  </BrowserRouter>,
  document.getElementById('root')
);