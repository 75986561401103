import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  spacedItem: {
    margin: '10px',
    minWidth: 150
  },
  table: {
    minWidth: 650,
  },
  select: {
    minWidth: 150
  },
  btn: {
    margin: '10px 27px'
  },
  selectText: {
    color: '#959595'
  },
  wrapper: {
    overflowX: 'auto',
    minWidth: 0
  },
  box: {
    border: '2px solid transparent'
  },
  selectedBox: {
    border: '2px solid #3f51b5'
  },
  selectedText: {
    color: '#3f51b5'
  }
})

export default useStyles;