import { useEffect, useState } from 'react';
import useAPI from 'hooks/useAPI';

function useComments(enrollmentNumber) {
  const [comments, setComments] = useState([]);
  const api = useAPI();

  useEffect(() => {
    api
      .get(`/users/${enrollmentNumber}/comments`)
      .then(res => {
        setComments(res.data.comments)
        console.log(res.data.message)
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [])

  return { comments, setComments };
}

export default useComments;