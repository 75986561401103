import React, { useEffect, useState } from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import { useParams, useHistory } from 'react-router-dom';
import useCustomer from 'hooks/useCustomer';
import useComments from 'hooks/useComments';
import FlexContainer from 'components/FlexContainer';
import BackButton from 'components/BackButton';
import useAPI from 'hooks/useAPI';
import useUser from 'hooks/useUser';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import StyledCard from 'components/StyledCard';
import CustomerCard from 'components/CustomerCard';
import AppointmentCard from 'components/AppointmentCard';
import { toast } from 'react-toastify';
import useGlobalLoading from 'hooks/useGlobalLoading';
import CommentCard from 'components/CommentCard/CommentCard';
import { useForm } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { secToMs } from '@sentry/tracing/dist/utils';

function CustomerInfo() {
  const { setIsLoading } = useGlobalLoading();
  const { enrollmentNumber } = useParams()
  const { customer } = useCustomer(enrollmentNumber);
  const [appointments, setAppointments] = useState();
  const [isReadyForUpload, setIsReadyForUpload] = useState(false);
  const api = useAPI();
  const history = useHistory();
  const { user } = useUser();
  const { comments, setComments } = useComments(enrollmentNumber);
  const { register, handleSubmit, reset } = useForm();

  const getComments = () => {
    return api
      .get(`/users/${enrollmentNumber}/comments`)
      .then(res => {
        return setComments(res.data.comments)
      })
      .catch((error) => toast.error(error.toString() || 'An unexpected error occurs, please contact administrator'));
  }

  const getAppointments = () => {
    return api
      .get(`/appointments/customer/${customer._id}`)
      .then(res => {
        console.log("res data", res.data.appointments)
        let pendingAppointments = [];
        res.data.appointments.forEach(appointment => {
          if (appointment.status !== "pending:upload") pendingAppointments.push(appointment)
        })
        setIsReadyForUpload(!!(pendingAppointments.length))
        return setAppointments(res.data.appointments)
      })
      .catch(err => console.log(err))
  }

  const uploadAndDataEntry = () => {
    setIsLoading(true);

    toast.info('Please wait while the server processes your request.');

    const utilityAccountId = localStorage.getItem('accountId');
    const data = { utilityAccountId, enrollmentNumber };

    api
      .post(`/heat/preprocess`, data)
      .then(res => {
        console.log(res)
        toast.success('Submissions marked "ready for upload" have been uploaded to the HEAT system.')
      })
      .catch(err => {
        toast.error(err.message)
        console.log(err.message)
      })
      .finally(() => setIsLoading(false));
    }

  useEffect(() => {
    if (customer) {
      getAppointments()
    }
    // eslint-disable-next-line
  }, [customer])

  const submitAppointment = () => {
    history.push(`/customers/${enrollmentNumber}/submit-appointment`)
  }

  const cancelJob = (appointment) => {
    if (window.confirm('Are you sure to cancel this job?')) {
      api
        .put(`/appointments/${appointment._id}`, { status: 'closed' })
        .then(() => {
          toast.success('Successfully updated an appointment.');
          getAppointments();
        })
        .catch((error) => toast.error(error.toString() || 'An unexpected error occurs, please contact administrator'));
    }
  }

  const submitComment = (data) => {
    api
      .post(`/users/${enrollmentNumber}/comment`, data)
      .then(res => {
        getComments();
        reset({ message: ""})
        toast.success('Comment submitted successfully.')
      })
      .catch((error) => toast.error(error.toString() || 'An unexpected error occurs, please contact administrator'));
  }

  return (
    <PageLayout title={customer?.firstName}>
      <Container maxWidth="md">
        <Box display="flex" my={1} justifyContent="flex-end">
          <BackButton url="/customers" ml={1} />
        </Box>
        <Grid container spacing={3}>
          <Grid item md={5}>
            <CustomerCard customer={customer} />
            <StyledCard>
              <h3>Comments</h3>
              {
                comments && comments.map(comment => (
                  <CommentCard comment={comment} key={comment._id}/>
                ))
              }
            </StyledCard>
            <StyledCard>
              <h3>Submit Comment</h3>
                <form onSubmit={handleSubmit(submitComment)}>
                  <Box flexDirection={'column'}>
                    <Box padding='10 0 0 0px'>
                      <TextField name="message" placeholder="" variant="outlined" size="small" inputRef={register} className='w-full' />
                    </Box>
                    <br></br>
                    <Box>
                      <Button 
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </form>
            </StyledCard>
          </Grid>
          <Grid item md={7}>
            <StyledCard>
              <FlexContainer flexDirection='column'>
                <h3>Appointments</h3>
                {
                  appointments && appointments.map(appointment => (
                    <AppointmentCard key={appointment._id} appointment={appointment} cancelJob={() => cancelJob(appointment)} />
                  ))
                }
                <Box width="50%" mx="auto">
                  <FlexContainer flexDirection='column' justifyContent='space-between' height='115px'>
                    <Button variant="contained" color="primary" className="w-full" onClick={submitAppointment}>Submit New Appointment</Button>
                    {(user.role === 'admin' || user.role === 'auditor.final' || user.role === 'auditor') && (
                      <Button variant="contained" color="primary" className='w-full' onClick={uploadAndDataEntry} disabled={isReadyForUpload}>
                        Upload and <br /> data entry
                      </Button>
                    )}
                  </FlexContainer>
                </Box>
              </FlexContainer>
            </StyledCard>
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  )
}

export default CustomerInfo;