import axiosWithAuth from 'utils/axiosWithAuth';
import { useHistory } from 'react-router-dom';

function useAPI() {
  const history = useHistory();
  const onError = (error) => {
    console.log(error)
    const message = error.response ? error.response.data.message : error.message;
    const code = error.response.status 
    if (code === 403) {
      history.push('/home')
      alert('Insufficient permissions')
    }
    else if (message === "Two step verification is needed.") {
      history.push('/two-step-verification')
      alert('Please use the two step verification page to verify your account using the latest HEAT verification code that was emailed to you.')
    }
    else if (code >= 400) {
      alert(message)
      history.push('/')
    }
  } 

  return axiosWithAuth({ onError });
}

export default useAPI;