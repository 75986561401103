import { useState, useEffect } from 'react';
import useAPI from 'hooks/useAPI';
import useUser from 'hooks/useUser';
import { useLocation } from 'react-router-dom';

function useNotifications() {
  const [notifications, setNotifications] = useState([])
  const { user } = useUser();
  const api = useAPI();
  const location = useLocation()

  useEffect(() => {
    if (user) {
      api
        .get(`/users/${user._id}/notifications`)
        .then(res => setNotifications(res.data.notifications))
        .catch(err => console.log(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user])

  return { notifications, setNotifications }
}

export default useNotifications;