import React from 'react';
import { Route, Redirect, } from 'react-router-dom';
import useUser from 'hooks/useUser';
import useAuth from 'hooks/useAuth';

function PrivateRoute(props) {
  const { accessToken } = useAuth();
  const { isAuthed, user } = useUser();

  if (!accessToken) return <Redirect to='/login'/>
  if (!isAuthed) return null;
  if (props.roles && props.roles.includes(user.role) === false) return <Redirect to='/home'/>

  return <Route {...props} />
}

export default PrivateRoute;