import React from 'react';
import { useHistory } from 'react-router';
import PageLayout from 'components/PageLayout/PageLayout';
import { Box, TextField, Button } from "@material-ui/core";
import StyledCard from 'components/StyledCard';
import BackButton from 'components/BackButton';
import { toast } from 'react-toastify';

import { useForm } from 'react-hook-form';
import useAPI from 'hooks/useAPI';
import useGlobalLoading from 'hooks/useGlobalLoading';

function PasswordReset() {
  const { setIsLoading } = useGlobalLoading();
  const { register, handleSubmit, reset } = useForm();
  const history = useHistory();
  const defaultValues = {
    currentPassword: '',
    password: ''
  };
  const api = useAPI();
  const resetPassword = data => {
    if (data.password !== data.confirmPassword) {
      return toast.error('New password inputs do not match!')
    }
    setIsLoading(true)
    api
      .put('/authentication/reset-password', data)
      .then(res => {
        reset(defaultValues)
        toast.success(res.data.message)
        history.push('/')
      })
      .catch(err => {
        console.log(err)
        reset(defaultValues)

      })
      .finally(() => setIsLoading(false))
  }

  return (
    <PageLayout title='Password Reset'>
      <Box width="400px" mx="auto" mt={10}>
        <form onSubmit={handleSubmit(resetPassword)}>
          <StyledCard px={15}>
            <Box display='flex' flexDirection='column'>
              <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                <TextField required type='password' placeholder='Current password' name='currentPassword' variant='outlined' size="small" inputRef={register} />
              </Box>
              <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                <TextField required type='password' placeholder='New password' name='password' variant='outlined' size="small" inputRef={register} />
              </Box>
              <Box width="100%" alignItems='left' display='flex' flexDirection='column' my={1}>
                <TextField required type='password' placeholder='Confirm new password' name='confirmPassword' variant='outlined' size="small" inputRef={register} />
              </Box>
              <Box justifyContent="flex-end" display="flex" my={1}>
                <Button type="submit" onClick={handleSubmit} color="primary" variant="contained">Submit</Button>
                <BackButton url='/' ml={1} />
              </Box>
            </Box>
          </StyledCard>
        </form>
      </Box>
    </PageLayout>
  )
}

export default PasswordReset;
