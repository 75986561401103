import React, { useEffect, useContext } from 'react';
import { NavContext } from 'providers/NavProvider';

import useStyles from './styles';

function PageLayout({ title, children }) {
  const { setTitle } = useContext(NavContext);
  const styles = useStyles();

  useEffect(() => {
    document.title = `Portal: ${title}`;
    setTitle(title); 

    return function() {
      document.title = 'Portal';
    }
  }, [title, setTitle])

  return (
    <div className={styles.root}>
      {children}
    </div>
  )
}

export default PageLayout;