import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PageLayout from 'components/PageLayout/PageLayout';
import useAPI from 'hooks/useAPI';
import useCustomer from 'hooks/useCustomer';
import useUser from 'hooks/useUser';
import DayJS from 'react-dayjs';
import { SuccessTypography, GrayTypography } from 'components/CustomTypography'
import { SecondaryButton } from 'components/CustomButton'
import FlexContainer from 'components/FlexContainer';
import useGlobalLoading from 'hooks/useGlobalLoading';

function ViewForms() { 
  const { setIsLoading } = useGlobalLoading();
  const { enrollmentNumber, appointmentId } = useParams();
  const { customer } = useCustomer(enrollmentNumber);
  const { url } = useRouteMatch();
  const [submissions, setSubmissions] = useState([]);
  const [ appointment, setAppointment ] = useState({})

  const [pictures, setPictures] = useState([]);
  const api = useAPI();
  const history = useHistory();
  const { user } = useUser();
  
  const getForms = () => {
    return api
      .get(`/appointments/${appointmentId}/submissions`)
      .then(res => setSubmissions(res.data.submissions))
      .catch(err => console.log(err))
  }

  const getPictures = async () => {
    await api
      .get(`/appointments/${appointmentId}/get-pictures`)
      .then(res => setPictures(res.data.pictures))
      .catch(err => console.log(err))
  }

  const markForManualEntry = () => {
    const data = { status: 'pending:manualEntry' } 

    if (window.confirm('Are you sure you want to mark this job for manual entry? This can only be undone by a system administrator.')) {
      api
        .put(`/appointments/${appointmentId}/update-status`, data)
        .then(res => {
          toast.success("Customer has been assigned the status: pending:manualEntry!")
          history.push(`/customers/${enrollmentNumber}`)
        })
        .catch(err => console.log(err.message))
    }
  }

  const uploadAndPreprocess = async () => {
    const dateUploadedObj = new Date();
    const dateUploaded = dateUploadedObj.toISOString();

    let isUploadSuccessful
    let isPreprocessingSuccessful

    setIsLoading(true);

    const data = {
      submissionIds: submissions,
      enrollmentNumber,
      customerLastName: customer.lastName,
      utilityAccountId: localStorage.getItem("accountId"),
      userId: user._id,
      dateUploaded 
    }

    if (window.confirm("Are you sure you want to perform upload and data entry for these forms? This is not reversible.")) {
      await api
        .post(`/heat/upload`, data)
        .then(res => {
          toast.success("PDF has been uploaded to HEAT, attempting data entry...")
          isUploadSuccessful = true;
        })
        .catch(err => {
          isUploadSuccessful = false;
          setIsLoading(false)
          toast.error(err.message)
        })

      if (isUploadSuccessful) {
        await api
          .post(`/heat/preprocess`, data)
          .then(res => {
            isPreprocessingSuccessful = true;
            toast.success("Data entry complete!")
            setIsLoading(false)
          })
          .catch(err => {
            isPreprocessingSuccessful = false;
            setIsLoading(false)
            toast.error(err.message)
          })
        }
    }

    if (isUploadSuccessful && isPreprocessingSuccessful) {
      api
        .put(`/appointments/${appointmentId}/update-status`, { status: "pending:verification", dateUploaded: dateUploaded })
        .then(res => {
          toast.success("Appointment and customer status set to pending verification.")
        })
        .catch(err => {
          toast.error(err.message)
        });
    }
  }

  const queueForFinalAudit = () => {
    const result = window.confirm('Are you sure you want to queue this submission ready for final audit?');
    if (result === false) {
      return
    }
    else {
      const submissionsReadyForFinalAudit = []
      submissions.forEach(submission => {
        if (submission.status === 'pending:finalAudit') submissionsReadyForFinalAudit.push(submission);
      })
      console.log(submissions);
      console.log(submissionsReadyForFinalAudit);

      if (submissions.length !== submissionsReadyForFinalAudit.length) {
        toast.warn('Not all forms have been marked ready for final audit!')
        return
      }
      else {
        const data = { status: 'pending:finalAudit' }

        api
          .put(`/appointments/${appointmentId}/update-status`, data)
          .then(res => {
            toast.success("Customer and appointment have been queued for final audit")
            history.push(`/customers/${enrollmentNumber}`)
          })
          .catch(err => console.log(err.message))
      }
    }
  }

  const sendForAdditionalWork = () => {
    const data = { status: 'pending:reschedule' } 

    api
      .put(`/appointments/${appointmentId}/update-status`, data)
      .then(res => {
        toast.success("Customer has been assigned the status: pending:reschedule!")
        history.push(`/customers/${enrollmentNumber}`)
      })
      .catch(err => console.log(err.message))
  }

  const readyForUpload = () => {

    /* checks current appointment submission statuses to avoid sending request that wouldn't
    be processed anyways */
    const submissionsPendingUpload = [];

    if (submissions) {
      submissions.forEach(submission => {
        if (submission.status === 'pending:upload') {
          submissionsPendingUpload.push(submission._id)
        }
      })
    };

    if (submissions.length !== submissionsPendingUpload.length) {
      toast.warn('Some forms have not been marked as ready for upload!')
    }
    else {
      const data = { status: 'pending:upload' };

      api
        .put(`/appointments/${appointmentId}/update-status`, data)
        .then(res => {
          console.log(res)
          toast.success('Appointment has been marked ready for upload.')
        })
        .catch(err => {
          console.log(err.message)
          toast.warning(err.message)
        })
    }
  }

  useEffect(() => {
    if (submissions) {
      submissions.forEach(submission => {
        console.log(submission.currentRevision)
        if (typeof(submission.currentRevision) === 'string')
        api
          .get(`/forms/${appointmentId}/revision/${submission.currentRevision}`)
          .then(res => {
            submission.currentRevision = res.data.revision
            console.log(res.data)
            console.log(submission)
          })
          .catch(err => console.log(err))
      })
    }
  }, [submissions]);

  useEffect(() => {
    if (customer) {
      getForms();
      getPictures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    api
      .get(`/appointments/${appointmentId}`)
      .then(res => setAppointment(res.data.appointment))
      .catch(err => {
        toast.error('Error retrieving the appointment data.')
        console.log(err)
      })
  }, []);

  return (
    <PageLayout title={`${customer?.firstName}'s appointment submissions`}>
      <Container size="lg">
        <Box p={2}>
          <Box mb={3}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => history.push(`/customers/${enrollmentNumber}`)}
            >
              ← RETURN TO BIN
            </Button>
          </Box>
          <Typography variant="h5" component="h3">Forms</Typography>
          <Box display="flex">
            <Box width="60%">
              {
                submissions && Object.keys(submissions).map((submissionKey, i) => {
                  const submission = submissions[submissionKey];
                  return (
                    <Box my={1} display="flex" key={submission?._id}>
                      <Box width="65%">
                        <Button 
                          variant="contained" 
                          color="primary" 
                          fullWidth
                          onClick={() => history.push(`${url}/form/${submission?.form.abbr}/${submission?.version}/${submission?._id}`)}
                        >
                          { submission?.form?.name }
                        </Button>
                        <Box display="flex" my={1}>
                          <Box width="150px">
                            <Typography>Submitted By: </Typography>
                          </Box>
                          <Typography>{`${submission?.createdBy?.firstName} ${submission?.createdBy?.lastName}`}</Typography>
                        </Box>
                        <Box display="flex" my={1}>
                          <Box width="150px">
                            <Typography>Submitted On: </Typography>
                          </Box>
                          <Typography>
                            <DayJS format="DD/MM/YYYY h:mm a">{ submission.createdAt }</DayJS>
                          </Typography>
                        </Box>
                        <Box display="flex" my={1}>
                          <Box width="100%">
                            <Typography>
                              {
                                submission?.status === 'pending:upload' ? 
                                'Ready for upload and data entry.' : 
                                submission?.status !== 'uploaded' ? 
                                  'Not ready for upload and data entry.' :
                                  'Uploaded and data entered in HEAT system.'
                              }
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      { user.role === 'auditor.initial' && (
                          <Box width="30%" marginLeft="5%">
                            {
                              (submission?.status === 'pending:initialAudit') ?
                              <GrayTypography>* Values not verified by intial auditor yet</GrayTypography>
                              :
                              <SuccessTypography>* Ready to queue for final audit</SuccessTypography>
                            }
                          </Box>
                        )} 
                      </Box>
                  );
                })
              }
            </Box>
            <Box width="25%" display="flex" justifyContent="center" marginLeft="15%" flexDirection="column">
              {/* {(user.role === 'admin' || user.role === 'auditor.final' || user.role === 'auditor') && !(appointment?.isManualEntry) && (
                <Button variant="contained" color="primary" onClick={readyForUpload}>
                  Ready appointment for upload <br /> and data entry
                </Button>
              )}
              {(user.role === 'auditor.initial') && !(appointment?.isManualEntry) && (
                <Button variant="contained" color="primary" onClick={queueForFinalAudit}>
                  Queue for final audit
                </Button>
              )} */}
              <Box my={2}>
                <SecondaryButton variant="contained" color="#FFA800" fullWidth onClick={sendForAdditionalWork}>
                  Needs Work
                </SecondaryButton>
              </Box>
              <Button variant="contained" color="primary">
                Sending to my <br /> pending bin
              </Button>
              <br></br>
              {(user.role === 'admin' || user.role === 'auditor.final' || user.role === "auditor") && !(appointment?.isManualEntry) && (
                <Box my={2}>
                  <Button padding='10px' variant="contained" color="primary" onClick={markForManualEntry}>
                    Mark for manual entry
                  </Button>
                </Box>
              )}
              {(user.role === 'admin' || user.role === 'auditor.final' || user.role === "auditor") && !(appointment?.isManualEntry) && (
                <Box>
                  <Button padding='10px' variant="contained" color="primary" onClick={uploadAndPreprocess}>
                    Upload and Data Entry
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Typography variant="h5" component="h3">Pictures</Typography>
          {pictures.map(srcLink => {
            return (
              <FlexContainer flexDirection='column' justifyContent='space-between'>
                <img src={srcLink}/>
              </FlexContainer>
            )
          })}
        </Box>
      </Container>
    </PageLayout>
  )
}

export default ViewForms