import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, TextField, Button } from "@material-ui/core";
import PageLayout from 'components/PageLayout';
import useAPI from 'hooks/useAPI';
import useGlobalLoading from 'hooks/useGlobalLoading';

function LeadFetcher() {
  const [numbersArray, setNumbersArray] = useState([]);
  const [error, setError] = useState(null);
  const utilityAccountId = localStorage.getItem('accountId');
  const [leadsInformation, setLeadsInformation] = useState([]);
  const api = useAPI();
  const { setIsLoading } = useGlobalLoading();

  const formatCSV = (leadsInformation) => {
    console.log(leadsInformation)
    let csv = "Enrollment #,housingtype,outreach ln,outreach fn,cust fn,cust ln,phone #,alt phone #,stNo,city,zip,signed\n";
    leadsInformation.forEach((lead) => {
      csv += `${lead.leadNumber.trim()},Single Family Home,Rigali,Vi,${lead.firstName},${lead.lastName},${lead.phoneNumber},${lead.altPhoneNumber},${lead.address},${lead.city},${lead.zip}, \n`;
    });
    return csv;
  };

  const downloadCSV = (csv) => {
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "leadsInformation.csv";
    link.click();
  }

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    if (!(file instanceof Blob)) {
      setError("The file you selected is not a valid text file.");
      return
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const fileContent = event.target.result;
      const numbers = fileContent.split("\n").filter(Boolean);
      setNumbersArray(numbers);
    };

    reader.readAsText(file);
  };

  const handleSubmit = () => {
    if (!numbersArray.length) alert("Please upload a valid file!")
    if (!utilityAccountId) alert("Please select a utility account from the customer bin page!")
    console.log(utilityAccountId, numbersArray)
    setIsLoading(true)
    api
      .post("/heat/get-lead-info", { leadArray: numbersArray, utilityAccountId })
      .then(res => {
        const csv = formatCSV(res.data.leadsInformation);
        downloadCSV(csv)
      })
      .catch(err => {
        console.log(err)
        alert(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <PageLayout title='Fetch Lead Information'>
      <Box padding='25px'>
        <input type="file" onChange={handleFileInput} />
        {error && <p>Error: {error}</p>}
        <p>Numbers: {numbersArray.join(", ")}</p>
      </Box>
      <Box padding='25px'>
        <button onClick={handleSubmit}>Submit</button>
      </Box>
    </PageLayout>
  )
}

export default LeadFetcher;