import MobileForm from "../../../../components/MobileForm";
import { useParams } from "react-router-dom";

export default function WXWO() {
  const { version, formId } = useParams();
  const useValidation = require(`./v${version}/useValidation`).default;
  const background = require(`./v${version}/background.png`);
  const fields = require(`./v${version}/fields.json`);
  const { width, height } = require(`./v${version}/dimensions.json`);
  return <MobileForm
    type="wxwo"
    version={version}
    width={width}
    height={height}
    useValidation={useValidation}
    background={background}
    fields={fields}
    formId={formId}
  />;
};