import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useAPI from 'hooks/useAPI';
import PageLayout from 'components/PageLayout/PageLayout';
import BackButton from 'components/BackButton';
import { getPersonName, getRoleLabel, ROLE_OPTIONS } from 'utils/helper';

function Users() {
  const api = useAPI();
  const history = useHistory();
  const [users, setUsers] = useState();
  const [role, setRole] = useState();
  const { handleSubmit } = useForm()
  const viewUser = user => {
    history.push(`/users/${user._id}`)
  }

  useEffect(() => {
    api
      .get('/users')
      .then(res => setUsers(res.data.users))
      .catch(err => console.log(err))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  const search = (data) => {

  }

  return (
    <PageLayout title='Users'>
      <form onSubmit={handleSubmit(search)}>
        <Box p={5} display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box ml={2}>
              <TextField required name="username" placeholder="Name" variant="outlined" size="small" />
            </Box>
            <Box ml={2} width="200px">
              <Select
                isClearable
                placeholder="Role"
                options={ROLE_OPTIONS}
                value={role}
                onChange={(value) => setRole(value)}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </Box>
            <Box ml={2}>
              <Button variant="contained" color="primary">Search</Button>
            </Box>
          </Box>
          <BackButton url='/' />
        </Box>
      </form>
      <Box p={5}>
        <Grid container spacing={3}>
          {users && users.map(user => (
            <Grid item xs={4} key={user._id}>
              <Card>
                <Box p={3} display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Typography gutterBottom><b>{ getRoleLabel(user.role) }</b></Typography>
                    <Typography gutterBottom>Name: { getPersonName(user) }</Typography>
                    <Typography>Username: { user.username }</Typography>
                  </Box>
                  <Box>
                    <Button variant="contained" color="primary" onClick={() => viewUser(user)}>Manage</Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </PageLayout>
  )
}

export default Users;