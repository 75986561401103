const VALIDATION_MESSAGES = {
  'systemType_heatOnly': 'Please select only one option for System type.', 
  'homeType_singleFamilyHome': 'Please select only one option for Home type.', 
  'fauLocation_garage': 'Please select only one option for FAU location.', 
  'fauLocation_otherText': 'If you select "other" for FAU location, please specify.', 
  'supplyDuctLocation_attic': 'Please select only one option for Supply duct location.', 
  'supplyDuctLocation_otherText': 'If you select "other" for supply duct location, please specify.', 
  "returnDuctLocation_attic": 'Please select only one option for Return duct location.', 
  "returnDuctLocation_otherText": 'If you select "other" for return duct location, please specify.', 
  "ductType_flexible": 'Please select at least one option for duct type.', 
} 

const useLogic = (control) => {
  const { getValues } = control;

  return {
    "systemType_heatOnly": () => ({
      validate: () => {
        const conditions = ["systemType_heatOnly", "systemType_splitSystem", "systemType_packageUnit"];
        const count = conditions.filter(condition => !!getValues(condition)).length;
        return (count === 1) || VALIDATION_MESSAGES['systemType_heatOnly'];
      },
    }),
    "homeType_singleFamilyHome": () => ({
      validate: () => {
        const conditions = ["homeType_singleFamilyHome", "homeType_mobileHome"];
        const count = conditions.filter(condition => !!getValues(condition)).length;

        return (count === 1) || VALIDATION_MESSAGES['homeType_singleFamilyHome'];
      },
    }),
    "fauLocation_garage": () => ({
      validate: () => {
        const conditions = ["fauLocation_garage", "fauLocation_closet", "fauLocation_attic", "fauLocation_crawlSpace", "fauLocation_roof", "fauLocation_other"];
        const count = conditions.filter(condition => !!getValues(condition)).length;

        return (count === 1) || VALIDATION_MESSAGES['fauLocation_garage'];
      },
    }),
    "fauLocation_otherText": () => ({
      validate: (text) => {
        const isOther = getValues("fauLocation_other");
        return (isOther === !!text) || VALIDATION_MESSAGES['fauLocation_otherText'];
      },
    }),
    "supplyDuctLocation_attic": () => ({
      validate: () => {
        const conditions = ["supplyDuctLocation_attic", "supplyDuctLocation_crawlSpace", "supplyDuctLocation_betweenFloors", "supplyDuctLocation_walls", "supplyDuctLocation_other"];
        const count = conditions.filter(condition => !!getValues(condition)).length;

        return (count === 1) || VALIDATION_MESSAGES['supplyDuctLocation_attic'];
      },
    }),
    "supplyDuctLocation_otherText": () => ({
      validate: (text) => {
        const isOther = getValues("supplyDuctLocation_other");
        return (isOther === !!text) || VALIDATION_MESSAGES['supplyDuctLocation_otherText'];
      },
    }),
    "returnDuctLocation_attic": () => ({
      validate: () => {
        const conditions = ["returnDuctLocation_attic", "returnDuctLocation_crawlSpace", "returnDuctLocation_betweenFloors", "returnDuctLocation_walls", "returnDuctLocation_other"];
        const count = conditions.filter(condition => !!getValues(condition)).length;

        return (count === 1) || VALIDATION_MESSAGES['returnDuctLocation_attic'];
      },
    }),
    "returnDuctLocation_otherText": () => ({
      validate: (text) => {
        const isOther = getValues("returnDuctLocation_other");
        return (isOther === !!text) || VALIDATION_MESSAGES['returnDuctLocation_otherText'];
      },
    }),
    "ductType_flexible": () => ({
      validate: (flexible) => {
        const rigid = getValues("ductType_rigid");
        const metallic = getValues("ductType_metallic");
        const nonMetallic = getValues("ductType_nonMetallic");
        const fiberglass = getValues("ductType_fiberglass");

        return (flexible || rigid || metallic || nonMetallic || fiberglass) || VALIDATION_MESSAGES['ductType_flexible'];
      },
    }),
  };
};

export default useLogic;
